//TODO: Move this to Environment and Customer service
import http from '../../http-common'

export const customerInfo = (customerId, token) => {
	return http.get(`/api/customers/${customerId}`, {
		headers: {
			'x-access-token': token,
		},
	})
}
