import { Modal } from 'react-bootstrap'
import NewOrder from './new-order/new.order.component'

const OrderForm = ({
	show = false,
	mode = 'create',
	handleClose = () => {},
	onOperationCompleted = () => {},
}) => {
	const handleCreate = (data) => {
		onOperationCompleted(
			`A new Order has been added. Order number: ${data.orderNumber}`,
		)
		handleClose()
	}

	const FormContent = () => {
		const modesContent = {
			create: () => (
				<NewOrder handleClose={handleClose} handleSubmit={handleCreate} />
			),
		}

		return modesContent[mode]()
	}

	return (
		<Modal
			fullscreen={true}
			show={show}
			onHide={handleClose}
			animation={true}
			contentClassName={'p-3'}
		>
			<Modal.Header closeButton>
				<Modal.Title>Create Order</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default OrderForm
