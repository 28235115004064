import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	impersonatedCustomer: null,
	showingModal: false,
}

export const impersonatedCustomerSlice = createSlice({
	name: 'impersonatedCustomer',
	initialState,
	reducers: {
		setImpersonatedCustomer: (state, action) => {
			state.impersonatedCustomer = action.payload
		},
		unsetImpersonatedCustomer: (state) => {
			state.impersonatedCustomer = null
		},
		showImpersonateModal: (state) => {
			state.showingModal = true
		},
		hideImpersonateModal: (state) => {
			state.showingModal = false
		},
	},
})

export const {
	setImpersonatedCustomer,
	unsetImpersonatedCustomer,
	showImpersonateModal,
	hideImpersonateModal,
} = impersonatedCustomerSlice.actions

export default impersonatedCustomerSlice.reducer
