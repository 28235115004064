import { Image, Navbar, Nav } from 'react-bootstrap'
import MenuOptions from './menu.options.component'
import logo from '../../resources/Logo.png'

const SideBar = () => {
	return (
		<div>
			<Navbar expand="md" className="navbar-dark flex-md-column">
				<Navbar.Brand>
					<Image src={logo} alt="ElevateERP" />
				</Navbar.Brand>
				<Navbar.Toggle />
				<Navbar.Collapse>
					<Nav className="flex-md-column">
						<MenuOptions />
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	)
}

export default SideBar
