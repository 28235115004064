import React from 'react'
import { Card } from 'react-bootstrap'

const InfoCard = ({ children, style }) => {
	return (
		<Card className="bg-grey border-0" style={{ ...style }}>
			<Card.Body>{children}</Card.Body>
		</Card>
	)
}

export default InfoCard
