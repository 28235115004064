import { Breadcrumb } from 'react-bootstrap'
import RMAList from './rma.list.component'

const RMAs = () => {
	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>RMAs</Breadcrumb.Item>
				</Breadcrumb>
			</div>
		)
	}

	return (
		<>
			<HeaderComponent />
			<RMAList />
		</>
	)
}

export default RMAs
