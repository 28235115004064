import React from 'react'
import { Card, Col, Dropdown, Row } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from './custom.menu.component'

export const WhiteShadowedCard = ({
	children,
	title,
	cardStyle = {},
	options,
	onClick,
}) => {
	return (
		<Card
			className="animated-card shadowed bg-white border-0 mb-4"
			style={{ ...{ width: '28rem', marginRight: '1rem' }, ...cardStyle }}
			role="button"
		>
			<Card.Body>
				<Row>
					<Col className="col-11">{title}</Col>
					<Col className="col-1">
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>{options}</Dropdown.Menu>
						</Dropdown>
					</Col>
				</Row>
				<Row onClick={onClick}>{children}</Row>
			</Card.Body>
		</Card>
	)
}
