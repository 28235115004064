import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	setImpersonatedCustomer,
	unsetImpersonatedCustomer,
} from '../state/impersonatedCustomer'
import Cookies from 'js-cookie'

function parseUser(apiUser) {
	return {
		...apiUser,
		customerAndClientCount:
			(apiUser.customers?.length ?? 0) + (apiUser.clients?.length ?? 0),
	}
}

function findCompanyByIdAndType(user, companyId, isClient) {
	const collection = isClient === '1' ? user.clients : user.customers
	return collection.find((company) => company.id === parseInt(companyId, 10))
}

export function useSessionCookies() {
	const dispatch = useDispatch()
	const impersonatedCustomer = useSelector(
		(state) => state.impersonatedCustomer,
	)

	const [user, setUser] = useState(null)
	const [currentCompany, setCurrentCompany] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const accessTokenCookie = Cookies.get('accessToken')
		if (accessTokenCookie) {
			fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth`, {
				method: 'GET',
				headers: {
					'x-access-token': accessTokenCookie,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					setUser(parseUser(data))
					const currentCompanyIdCookie = Cookies.get('currentCompanyId')
					const currentCompanyIsClientCookie = Cookies.get(
						'currentCompanyIsClient',
					)
					if (currentCompanyIdCookie && currentCompanyIsClientCookie) {
						try {
							const currComp = findCompanyByIdAndType(
								data,
								currentCompanyIdCookie,
								currentCompanyIsClientCookie,
							)
							setCurrentCompany(currComp)
						} catch (error) {
							console.error('Error parsing currentCompany cookie:', error)
							setCurrentCompany(null)
						}
					}
				})
				.catch((error) => {
					console.error('Error fetching user data:', error)
					setUser(null)
					setCurrentCompany(null)
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			setLoading(false)
		}
	}, [])

	// Load the impersonated user into the Redux state from the cookie.
	useEffect(() => {
		const impersonatedCustomerCookie = Cookies.get('impersonatedCustomer')
		if (impersonatedCustomerCookie) {
			let impersonateCustomer
			try {
				impersonateCustomer = JSON.parse(impersonatedCustomerCookie)
			} catch (error) {
				console.error('Error parsing impersonatedCustomer cookie:', error)
			}
			dispatch(setImpersonatedCustomer(impersonateCustomer))
		}
	}, [dispatch])

	// Update the cookie based on changes to the impersonated user Redux state.
	useEffect(() => {
		if (impersonatedCustomer) {
			Cookies.set(
				'impersonatedCustomer',
				JSON.stringify(impersonatedCustomer),
				{
					expires: 1,
					sameSite: 'Strict',
					secure: true,
				},
			)
		} else {
			Cookies.remove('impersonatedCustomer')
		}
	}, [impersonatedCustomer])

	return {
		user,
		loading,
		currentCompany,
		setUser(apiUser) {
			if (apiUser.accessToken) {
				Cookies.set('accessToken', apiUser.accessToken, {
					expires: 1, //TODO: Ensure this expires when the token expires
					sameSite: 'Strict',
				})
			}
			const user = parseUser(apiUser)
			setUser(user)
			return user
		},
		setCurrentCompany(company, { isPersisted } = { isPersisted: false }) {
			if (isPersisted) {
				Cookies.set('currentCompanyId', company.id, {
					expires: 1,
					sameSite: 'Strict',
				})
				Cookies.set('currentCompanyIsClient', company.isClient, {
					expires: 1,
					sameSite: 'Strict',
				})
			}
			setCurrentCompany(company)
		},
		clearSession() {
			Cookies.remove('user')
			Cookies.remove('currentCompany')
			// This will clear the cookie via the effect above.
			dispatch(unsetImpersonatedCustomer())
			setUser(null)
			setCurrentCompany(null)
		},
	}
}
