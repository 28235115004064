import { useMemo } from 'react'
import { useTable, useSortBy } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { containerItem } from '../shared/styles'
import { accessors } from './users.table.accessors.component'

const UsersTable = ({ isClient, users, setDoFetch, handleEditUser }) => {
	const columns = useMemo(
		() => accessors(isClient, setDoFetch, handleEditUser),
		[isClient, setDoFetch, handleEditUser],
	)
	const data = useMemo(
		() => users.filter((usr) => isClient === usr.isClient),
		[isClient, users],
	)
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data },
		useSortBy,
	)

	return (
		<div style={containerItem}>
			<BTable responsive hover {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup, i) => (
						<tr key={i} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, i) => (
								<th
									key={i}
									{...column.getHeaderProps(column.getSortByToggleProps())}
								>
									{column.render('Header')}
									<span>
										{!column.isSorted ? '' : column.isSortedDesc ? ' ▼' : ' ▲'}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{rows.map((row, i) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell, i) => (
									<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
								))}
							</tr>
						)
					})}
				</tbody>
			</BTable>
		</div>
	)
}

export default UsersTable
