import http from '../../http-common'

export const findAll = (environmentId, companyId, token) => {
	return http.get(
		`/api/environments/${environmentId}/company/${companyId}/queries`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const findOne = (environmentId, companyId, queryId, token) => {
	return http.get(
		`/api/environments/${environmentId}/company/${companyId}/queries/${queryId}`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const fieldsList = (environmentId, companyId, epicorQueryId, token) => {
	return http.get(
		`/api/environments/${environmentId}/company/${companyId}/queries/fields?epicorQueryId=${epicorQueryId}`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const fieldsListCustomer = (customerId, queryId, token) => {
	return http.get(`/api/customers/${customerId}/queries/${queryId}/fields`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const execute = (customerId, queryId, token, outputType = '') => {
	const outputTypeQuery = outputType ? `?outputType=${outputType}` : ''
	return http.get(
		`/api/customers/${customerId}/queries/${queryId}${outputTypeQuery}`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const updateRecords = (customerId, queryId, token, data) => {
	return http.post(`/api/customers/${customerId}/queries/${queryId}`, data, {
		headers: {
			'x-access-token': token,
			'Content-Type': 'application/json',
		},
	})
}

export const create = (environmentId, companyId, data, token) => {
	return http.post(
		`/api/environments/${environmentId}/company/${companyId}/queries`,
		data,
		{
			headers: {
				'x-access-token': token,
				'Content-Type': 'application/json',
			},
		},
	)
}

export const update = (environmentId, companyId, queryId, data, token) => {
	return http.put(
		`/api/environments/${environmentId}/company/${companyId}/queries/${queryId}`,
		data,
		{
			headers: {
				'x-access-token': token,
				'Content-Type': 'application/json',
			},
		},
	)
}

export const deleteById = (environmentId, companyId, queryId, token) => {
	return http.delete(
		`/api/environments/${environmentId}/company/${companyId}/queries/${queryId}`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}
