import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { unsetImpersonatedCustomer } from '../../state/impersonatedCustomer'

const ImpersonatedUserTopBar = () => {
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)
	const dispatch = useDispatch()

	return (
		<Row className="bg-red text-white">
			<Col className="my-auto mx-auto px-4">
				You are viewing as {impersonatedCustomer?.name} (
				{impersonatedCustomer?.custId})
			</Col>
			<Col xs={2}>
				<u
					className="float-end"
					role="button"
					onClick={() => {
						dispatch(unsetImpersonatedCustomer())
					}}
				>
					<div className="d-md-none">Exit</div>
					<div className="d-none d-md-block">Exit View As</div>
				</u>
			</Col>
		</Row>
	)
}

export default ImpersonatedUserTopBar
