import React, { useState, useEffect } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Row } from 'react-bootstrap'
import useAuth from '../../auth/useAuth'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { EpicorOrderService } from '../../services/epicor'

const AsyncPartTypeahead = ({ ident, onChange }) => {
	const { user, currentCompany } = useAuth()
	const [options, setOptions] = useState([])
	const [part, setPart] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	// Load the part results.
	useEffect(() => {
		if (part.length < 3) {
			//Must have 3 characters or more
			setOptions([])
			return
		}

		const searchTimeout = setTimeout(async () => {
			setIsLoading(true)
			try {
				const response = await EpicorOrderService.partSearch(
					currentCompany.company.environmentId,
					currentCompany.companyId,
					part,
					user.accessToken,
				)
				setOptions(response.data)
			} catch (error) {
				console.error('Error fetching results:', error)
			} finally {
				setIsLoading(false)
			}
		}, 300) // Adding a debounce to limit API calls

		return () => {
			clearTimeout(searchTimeout) // Cleanup the timeout
		}
	}, [
		currentCompany.company.environmentId,
		currentCompany.companyId,
		part,
		user.accessToken,
	])

	const filterBy = () => true

	return (
		<Typeahead
			id={ident}
			filterBy={filterBy}
			onInputChange={setPart}
			onChange={([selected]) => {
				onChange(selected)
			}}
			options={options}
			isLoading={isLoading}
			labelKey="partNum"
			placeholder="Part Number..."
			renderMenuItemChildren={({ partNum, partDescription }) => (
				<>
					<Row
						style={{
							fontWeight: 'bold',
							textAlign: 'left',
							whiteSpace: 'wrap',
						}}
					>
						{partNum}
					</Row>
					<Row
						style={{
							textAlign: 'left',
							whiteSpace: 'wrap',
						}}
					>
						{partDescription}
					</Row>
				</>
			)}
		/>
	)
}

export default AsyncPartTypeahead
