import { useMemo, useState, Fragment } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { accessors } from './invoice.table.accessors.component'
import RecordLines from '../shared/lines.list.component'
import { linesAccessors } from './lines.table.accessors.component'
import TableHead from '../shared/table.header.component'
import _ from 'lodash'

const InvoicesTable = ({ invoices, printInvoice, payInvoice }) => {
	const [expandedRows, setExpandedRows] = useState({})
	const columns = useMemo(() => accessors(printInvoice, payInvoice), [invoices])
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data: invoices },
		useSortBy,
	)

	const TableBody = () => {
		const toggleCollapse = (invoiceNum, cell) => () => {
			if (cell.column.id === '⚡') return
			setExpandedRows((prevState) => {
				const nextState = {}
				Object.keys(prevState).forEach((key) => {
					nextState[key] = false
				})
				nextState[invoiceNum] = !prevState[invoiceNum]
				return nextState
			})
		}

		return (
			<tbody>
				{rows.map((row, i) => {
					prepareRow(row)
					const isExpanded = expandedRows[row.original.invoiceNum]

					return (
						<Fragment key={row.original.invoiceNum}>
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => (
									<td
										{...cell.getCellProps()}
										onClick={toggleCollapse(row.original.invoiceNum, cell)}
									>
										{cell.render('Cell')}
									</td>
								))}
							</tr>
							{isExpanded && (
								<tr key={row.original.invoiceNum + '.Lines'}>
									<td
										key={row.original.invoiceNum + '.LinesCell'}
										colSpan={row.cells.length}
									>
										<RecordLines
											record={row.original.invoiceNum}
											type="invoice"
											linesAccessors={linesAccessors}
										/>
									</td>
								</tr>
							)}
						</Fragment>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(invoices) ? (
		<BTable responsive hover {...getTableProps()}>
			<TableHead headerGroups={headerGroups} />
			<TableBody />
		</BTable>
	) : (
		<p>The current customer does not have any invoices.</p>
	)
}

export default InvoicesTable
