import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import UsersTable from './users.table.component'

const UsersList = ({ refreshList, isClient, handleEditUser }) => {
	const [users, setUsers] = useState([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [doFetch, setDoFetch] = useState(true)

	const userDataService = useUserDataService()

	useEffect(() => {
		if (doFetch || refreshList) {
			setLoading(true)
			userDataService
				?.getAll()
				.then((response) => setUsers(response.data))
				.catch((error) => setError(error.message))
				.finally(() => {
					setLoading(false)
					setDoFetch(false)
				})
		}
	}, [doFetch, refreshList, userDataService])

	return (
		<div style={containerItem}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={7} />
			) : (
				<UsersTable
					isClient={isClient}
					users={users}
					setDoFetch={setDoFetch}
					handleEditUser={handleEditUser}
				/>
			)}
		</div>
	)
}

export default UsersList
