import { useState } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'
import EditUser from './edit.user.component'
import NewUser from './new.user.component'

const UserForm = ({
	show = false,
	mode = 'create',
	handleClose = () => {},
	onOperationCompleted = () => {},
	userData,
	isClient,
}) => {
	const [error, setError] = useState(null)

	const userDataService = useUserDataService()

	const handleCreate = (newUser) => {
		if (newUser.roleId.length === 0) delete newUser.roleId
		userDataService
			?.invite(newUser)
			.then((res) => {
				onOperationCompleted(res.data.message)
				handleClose()
			})
			.catch((error) => {
				setError(error.message)
			})
	}

	const handleUpdate = (formData) => {
		userDataService
			.update(formData.userId, formData)
			.then(() => {
				onOperationCompleted(`Updated ${formData.email}`)
				setError('')
				handleClose()
			})
			.catch((error) => {
				setError(error.message)
			})
	}

	const FormContent = () => {
		const modesContent = {
			create: () => (
				<NewUser handleClose={handleClose} handleSubmit={handleCreate} />
			),
			edit: () => (
				<EditUser
					handleClose={handleClose}
					handleSubmit={handleUpdate}
					userData={userData}
					isClient={isClient}
					adminEdit={true}
				/>
			),
		}

		return modesContent[mode]()
	}

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					{mode === 'create' ? 'Add User' : 'Edit User'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error ? <Alert variant="danger">{error}</Alert> : ''}
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default UserForm
