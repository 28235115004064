import { useEffect, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'

const EditUser = ({
	handleClose,
	handleSubmit,
	userData,
	adminEdit = false,
}) => {
	const [error, setError] = useState('')
	const [roles, setRoles] = useState([])
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [roleId, setRoleId] = useState(0)
	const payload = { ...userData, firstName, lastName, roleId }

	const userDataService = useUserDataService()

	const handleOnSubmit = (e) => {
		e.preventDefault()
		handleSubmit(payload)
	}

	useEffect(() => {
		setFirstName(userData.firstName)
		setLastName(userData.lastName)
		setRoleId(userData.roleId)
		if (adminEdit && !userData.isClient && userData.roleName !== 'Admin') return
		userDataService
			?.getRolesByType(userData.isClient ? 'Client' : 'Customer')
			.then((roles) => {
				setRoles(roles)
				setError('')
			})
			.catch((e) => {
				setError(e)
			})
	}, [adminEdit, userDataService, userData])

	return (
		<>
			{error ? <Alert variant="danger">{error}</Alert> : ''}
			<Form onSubmit={handleOnSubmit}>
				<div className="d-grid gap-4">
					{error ? <Alert variant="danger">{error}</Alert> : null}
					<Form.Group controlId="firstName" className="required">
						<Form.Label>First Name</Form.Label>
						<Form.Control
							required
							type="text"
							name="firstName"
							placeholder="Enter First Name"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter first name
						</Form.Control.Feedback>
						{adminEdit && (
							<Form.Text className="text-muted">
								Do NOT add any text other than the person's preferred name.
							</Form.Text>
						)}
					</Form.Group>
					<Form.Group controlId="lastName" className="required">
						<Form.Label>Last Name</Form.Label>
						<Form.Control
							required
							type="text"
							name="lastName"
							placeholder="Enter Last Name"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter last name
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="email">
						<Form.Label>Email</Form.Label>
						<Form.Control
							disabled
							name="email"
							type="email"
							className="form-control"
							value={userData.email}
						/>
					</Form.Group>
					{adminEdit && (
						<Form.Group controlId="companyName">
							<Form.Label>Company</Form.Label>
							<Form.Control
								disabled
								name="companyName"
								type="text"
								className="form-control"
								value={userData.companyName}
							/>
						</Form.Group>
					)}
					{adminEdit && (
						<Form.Group controlId="roleId" className="required">
							<Form.Label>Role</Form.Label>
							<Form.Select
								name="roleId"
								aria-label="role"
								value={roleId}
								onChange={(e) => setRoleId(e.target.value)}
							>
								<option key="0" value="0">
									Choose role
								</option>
								{roles.map(({ id, name }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					)}
					{adminEdit && (
						<Form.Group controlId="customerName">
							<Form.Label>Customer</Form.Label>
							<Form.Control
								disabled
								name="customerName"
								type="text"
								className="form-control"
								value={userData.customerName}
							/>
						</Form.Group>
					)}
					<Button variant="primary mt-3" type="submit">
						Save
					</Button>
					<Button variant="light" onClick={handleClose}>
						Cancel
					</Button>
				</div>
			</Form>
		</>
	)
}

export default EditUser
