import moment from 'moment'
import { Dropdown } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = (printOrder) => [
	{
		Header: 'Orders List',
		columns: [
			{
				Header: 'Order#',
				accessor: 'orderNum',
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Order Date',
				accessor: (item) =>
					item.orderDate != null
						? moment(item.orderDate).format('YYYY-MM-DD')
						: '',
			},
			{
				Header: 'Order Amount',
				accessor: (item) =>
					parseFloat(
						item.totalCharges + item.totalTax + item.totalMisc,
					).toFixed(2),
			},
			{
				Header: 'Ship Via',
				accessor: 'shipViaCode',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.needByDate != null
						? moment(item.needByDate).format('YYYY-MM-DD')
						: '',
			},
			{
				Header: 'Status',
				accessor: (item) => (item.openOrder ? 'Open' : ''),
			},
			{
				Header: '⚡',
				accessor: (order) => {
					return (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => printOrder(order.orderNum)}
								>
									Download Order
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)
				},
			},
		],
	},
]
