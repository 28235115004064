import useAuth from '../../auth/useAuth'
import { Modal, Button, Row, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setImpersonatedCustomer } from '../../state/impersonatedCustomer'
import { useEffect, useState } from 'react'
import { customersList } from '../../services/customer.service'

const ImpersonateModal = ({ show, onSelect, onClose }) => {
	const { user, currentCompany } = useAuth()
	const [customers, setCustomers] = useState([])
	const [selectedCustomerId, setSelectedCustomerId] = useState(null)
	const dispatch = useDispatch()

	useEffect(() => {
		if (currentCompany && currentCompany.company)
			customersList(
				user.accessToken,
				currentCompany.company.id,
				currentCompany.company.environmentId,
			).then((response) => setCustomers(response.data))
	}, [currentCompany])

	const dispatchImpersonate = (e) => {
		const cust = customers.find((c) => c.id === parseInt(selectedCustomerId))
		dispatch(setImpersonatedCustomer(cust))
		onSelect()
	}

	return (
		<Modal
			size="lg"
			show={show}
			animation={true}
			contentClassName={'p-3'}
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>View as Customer</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Row className="d-grid gap-4 mt-4">
					<Form.Group controlId="form.shipTo">
						<Form.Label>Select customer you want to impersonate</Form.Label>
						<Form.Select
							name="impersonateCustomer"
							onChange={(e) => setSelectedCustomerId(e.target.value)}
							value={selectedCustomerId || ''}
						>
							<option>Select customer</option>
							{customers.map((c) => (
								<option key={c.id} value={c.id}>
									{c.name} ({c.custId})
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Button
						variant="primary"
						onClick={dispatchImpersonate}
						disabled={!selectedCustomerId}
					>
						View as Customer
					</Button>
					<Button variant="secondary" onClick={onSelect}>
						Cancel
					</Button>
				</Row>
			</Modal.Body>
		</Modal>
	)
}

export default ImpersonateModal
