import { loadStripe } from '@stripe/stripe-js'

let stripePromise
const getStripe = () => {
	//console.log(process.env.STRIPE_KEY)
	if (!stripePromise) {
		stripePromise = loadStripe(
			process.env.STRIPE_KEY ?? 'pk_test_TYooMQauvdEDq54NiTphI7jx',
		)
	}
	return stripePromise
}

export default getStripe
