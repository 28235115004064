import { Dropdown } from 'react-bootstrap'
import useAuth from '../../auth/useAuth'
import { WhiteShadowedCard } from '../shared/white.shadowed.card.component'

const ConnectionCard = ({
	environment,
	handleRemove,
	handleSetAsLive,
	onClick,
	onVerify,
}) => {
	const ConnectionCardContent = () => {
		return (
			<>
				<p className="mt-4 text-truncate">{environment.url}</p>
				<div>
					<span
						className={environment.verified ? 'text-green' : 'text-danger'}
						style={{ fontSize: '64px', verticalAlign: 'middle' }}
					>
						•
					</span>
					<span className="mb-0" style={{ position: 'relative', top: '2px' }}>
						{' '}
						{environment.verified ? 'Connected' : 'Not Connected'}
					</span>
					{environment.isLive ? (
						<span
							className="badge bg-light-green text-green"
							style={{
								position: 'absolute',
								bottom: '1.5rem',
								right: '1.5rem',
							}}
						>
							Live
						</span>
					) : null}
				</div>
			</>
		)
	}

	const ConnectionOptions = () => {
		const { user } = useAuth()

		return (
			<>
				<Dropdown.Item
					eventKey="1"
					onClick={() => handleRemove(environment.id, user.accessToken)}
				>
					Remove
				</Dropdown.Item>
				<Dropdown.Item eventKey="2" onClick={() => onVerify(environment.id)}>
					{environment.verified ? 'Reconnect' : 'Connect'}
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="2"
					onClick={() =>
						handleSetAsLive(
							environment.id,
							user.accessToken,
							!environment.isLive,
						)
					}
				>
					{environment.isLive ? 'Set as not Live' : 'Set as Live'}
				</Dropdown.Item>
			</>
		)
	}

	return (
		<WhiteShadowedCard
			key={environment.id}
			cardStyle={{ maxHeight: '200px' }}
			title={<h4>{environment.localName}</h4>}
			options={<ConnectionOptions />}
			onClick={onClick}
		>
			<ConnectionCardContent />
		</WhiteShadowedCard>
	)
}

export default ConnectionCard
