import { useMemo, useState, Fragment } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { accessors } from './shipment.table.accessors.component'
import RecordLines from '../shared/lines.list.component'
import { linesAccessors } from './lines.table.accessors.component'
import TableHead from '../shared/table.header.component'
import _ from 'lodash'

const ShipmentsTable = ({ shipments, printShipment, printBOL }) => {
	const [expandedRows, setExpandedRows] = useState({})
	const columns = useMemo(() => accessors(printShipment, printBOL), [shipments])
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data: shipments },
		useSortBy,
	)

	const TableBody = () => {
		const toggleCollapse = (shipHeadPackNum, cell) => () => {
			if (cell.column.id === '⚡' || cell.column.id === 'Tracking Number')
				return
			setExpandedRows((prevState) => {
				const nextState = {}
				Object.keys(prevState).forEach((key) => {
					nextState[key] = false
				})
				nextState[shipHeadPackNum] = !prevState[shipHeadPackNum]
				return nextState
			})
		}
		return (
			<tbody>
				{rows.map((row, i) => {
					prepareRow(row)
					const isExpanded = expandedRows[row.original.shipHeadPackNum]

					return (
						<Fragment key={row.original.shipHeadPackNum}>
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => (
									<td
										{...cell.getCellProps()}
										onClick={toggleCollapse(row.original.shipHeadPackNum, cell)}
									>
										{cell.render('Cell')}
									</td>
								))}
							</tr>
							{isExpanded && (
								<tr key={row.original.shipHeadPackNum + '.Lines'}>
									<td
										key={row.original.shipHeadPackNum + '.LinesCell'}
										colSpan={row.cells.length}
									>
										<RecordLines
											record={row.original.shipHeadPackNum}
											type="shipment"
											linesAccessors={linesAccessors}
										/>
									</td>
								</tr>
							)}
						</Fragment>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(shipments) ? (
		<BTable responsive hover {...getTableProps()}>
			<TableHead headerGroups={headerGroups} />
			<TableBody />
		</BTable>
	) : (
		<p>The current customer does not have any shipments.</p>
	)
}

export default ShipmentsTable
