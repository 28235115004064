import http from '../../http-common'

export const findAll = (customerId, token) => {
	return http.get(`/api/customers/${customerId}/rmas`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const getLines = (customerId, rmaNum, token) => {
	return http.get(`/api/customers/${customerId}/rmas/${rmaNum}/lines`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const print = (customerId, rmaNum, token) => {
	return http.get(`/api/customers/${customerId}/rmas/${rmaNum}/print`, {
		headers: {
			'x-access-token': token,
		},
	})
}
