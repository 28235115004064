import http from '../http-common'

export const customersList = (token, companyId, environmentId, startsWith) => {
	let url = `/api/environments/${environmentId}/company/${companyId}/customers`
	if (startsWith) url = `${url}?startsWith=${startsWith}`

	return http.get(url, {
		headers: {
			'x-access-token': token,
		},
	})
}
