import useAuth from '../../auth/useAuth'
import { useSelector } from 'react-redux'
import { EpicorQueryService } from '../../services/epicor/'
import { useEffect, useMemo, useState } from 'react'
import { Alert, Modal, Button, ButtonToolbar, Form } from 'react-bootstrap'
import { useTable } from 'react-table'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'

const RunQueryForm = ({ show = false, query, onClose = () => {} }) => {
	const { user, currentCompany } = useAuth()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [fieldList, setFieldList] = useState([])
	const [queryData, setQueryData] = useState([])
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)
	const customerId = impersonatedCustomer?.id ?? currentCompany?.attributes?.id

	useEffect(() => {
		if (customerId === undefined) {
			setError('Customer is not selected')
			return
		}
		setLoading(true)
		Promise.all([
			EpicorQueryService.fieldsListCustomer(
				customerId,
				query.id,
				user.accessToken,
			),
			EpicorQueryService.execute(customerId, query.id, user.accessToken),
		])
			.then(([fieldsResponse, queryResponse]) => {
				setFieldList(fieldsResponse.data)
				setQueryData(queryResponse.data)
			})
			.catch((error) => {
				setError(error.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [customerId, query.id, user.accessToken])

	const updateQueryDataField = (row, alias, value) => {
		setQueryData((prevQueryData) => {
			const updatedQueryData = prevQueryData.map((item) => {
				if (item.SysRowID === row.SysRowID) {
					return {
						...item,
						[alias]: value,
						RowMod: item.RowMod === 'A' ? 'A' : 'U',
					}
				}
				return item
			})
			return updatedQueryData
		})
	}

	const columns = useMemo(() => {
		return fieldList.map((field) => ({
			Header: field.fieldLabel,
			accessor: field.alias,
			Cell: ({ cell: { value }, row: { original } }) => {
				if (!field.updatable || !query.allowUpdate) {
					return value
				}
				return (
					<FieldControl
						dataType={field.dataType}
						value={value}
						onUpdate={(newValue) => {
							updateQueryDataField(original, field.alias, newValue)
						}}
					/>
				)
			},
		}))
	}, [fieldList, query.allowUpdate])

	const { getTableProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data: queryData,
	})

	const onAddNewRecord = () => {
		const newRecord = fieldList.reduce(
			(acc, field) => {
				acc[field.alias] = field.dataType === 'bit' ? false : ''
				return acc
			},
			{ RowMod: 'A' },
		)
		setQueryData((prevQueryData) => [...prevQueryData, newRecord])
	}

	const onSaveChanges = async () => {
		setLoading(true)
		try {
			const recordsToUpdate = queryData.filter(
				(record) => record.RowMod === 'U' || record.RowMod === 'A',
			)
			await EpicorQueryService.updateRecords(
				customerId,
				query.id,
				user.accessToken,
				recordsToUpdate,
			).then(() => {
				setQueryData((prevQueryData) =>
					prevQueryData.map((record) => ({
						...record,
						RowMod: '',
					})),
				)
				alert('Changes saved successfully!')
			})
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}

	const ModalHeader = () => {
		return (
			<Modal.Header>
				<ButtonToolbar>
					{query.allowAddNew && (
						<Button style={{ marginRight: '0.5rem' }} onClick={onAddNewRecord}>
							Add New Record
						</Button>
					)}
					{query.allowUpdate && (
						<Button style={{ marginRight: '0.5rem' }} onClick={onSaveChanges}>
							Save Changes
						</Button>
					)}
				</ButtonToolbar>
			</Modal.Header>
		)
	}

	return (
		<Modal
			fullscreen={true}
			show={show}
			onHide={onClose}
			animation={true}
			contentClassName={'p-3'}
		>
			<Modal.Header closeButton>
				<Modal.Title>{query.queryName}</Modal.Title>
			</Modal.Header>
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<>
					<ModalHeader />
					<Modal.Body>
						{error ? (
							<Alert
								variant="danger"
								dismissible
								onClose={() => setError(null)}
							>
								{error}
							</Alert>
						) : null}
						{fieldList.length === 0 || queryData.length === 0 ? (
							'No Records Found!'
						) : (
							<>
								<table
									{...getTableProps()}
									className="table table-striped table-bordered table-hover"
								>
									<thead>
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													<th {...column.getHeaderProps()}>
														{column.render('Header')}
													</th>
												))}
											</tr>
										))}
									</thead>
									<tbody>
										{rows.map((row, i) => {
											prepareRow(row)
											return (
												<tr {...row.getRowProps()}>
													{row.cells.map((cell) => (
														<td
															{...cell.getCellProps()}
															className={cell.column.className}
														>
															{cell.render('Cell')}
														</td>
													))}
												</tr>
											)
										})}
									</tbody>
								</table>
							</>
						)}
					</Modal.Body>
				</>
			)}
		</Modal>
	)
}

const FieldControl = ({ dataType, value, onUpdate }) => {
	switch (dataType) {
		case 'nvarchar':
			return (
				<Form.Control
					type="text"
					onChange={(event) => {
						onUpdate(event.target.value)
					}}
					value={value}
					style={{ minWidth: 150 }}
				/>
			)
		case 'int':
		case 'decimal':
			return (
				<Form.Control
					type="number"
					onChange={(event) => {
						onUpdate(event.target.value)
					}}
					value={value}
				/>
			)
		case 'date':
		case 'datetime':
			return (
				<Form.Control
					type="date"
					onChange={(event) => {
						onUpdate(event.target.value)
					}}
					value={value ? new Date(value).toISOString().substring(0, 10) : ''}
				/>
			)
		case 'bit':
			return (
				<Form.Check
					type="checkbox"
					onChange={(event) => {
						onUpdate(event.target.checked)
					}}
					checked={value}
				/>
			)
		default:
			return value
	}
}

export default RunQueryForm
