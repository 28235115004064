import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import Toaster from '../shared/toaster.component'
import OrderForm from './order.form.component'
import OrdersList from './orders.list.component'

const Orders = () => {
	const [showForm, setShowForm] = useState(false)
	const [mode, setMode] = useState('create')
	const [toastMessage, setToastMessage] = useState(null)
	const [refreshList, setRefreshList] = useState(0)

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Orders</Breadcrumb.Item>
				</Breadcrumb>

				<Button
					style={{ float: 'right' }}
					onClick={() => {
						setMode('create')
						setShowForm(true)
					}}
				>
					Create New Order
				</Button>
			</div>
		)
	}

	const handleRefresh = (message) => {
		setRefreshList((prevRefreshList) => prevRefreshList + 1)
		if (message) {
			setToastMessage(message)
			setTimeout(() => {
				setToastMessage(null)
			}, 3000)
		}
	}

	return (
		<>
			<HeaderComponent />
			<OrdersList refreshList={refreshList} />
			<OrderForm
				show={showForm}
				mode={mode}
				handleClose={() => setShowForm(false)}
				onOperationCompleted={handleRefresh}
			/>
			<Toaster msg={toastMessage} />
		</>
	)
}

export default Orders
