import { Button, Dropdown } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'

export const accessors = (isClient, setDoFetch, handleEditUser) => {
	const data = [
		{
			Header: 'Company',
			columns: [
				{
					Header: 'Company',
					accessor: 'companyName',
				},
			],
		},
		{
			Header: 'User',
			columns: [
				{
					Header: 'Name',
					accessor: ({ firstName, lastName }) => (
						<>
							<span className="circle d-md-none d-inline">
								{firstName.charAt(0)}
								{lastName.charAt(0)}
							</span>
							<span>
								{firstName} {lastName}
							</span>
						</>
					),
				},
				{
					Header: 'Email',
					accessor: 'email',
				},
				{
					Header: 'Roles',
					accessor: 'roleName',
				},
				...(isClient
					? [] // If isClient is true, don't include the "Customer" column
					: [
							{
								Header: 'Customer',
								accessor: (u) => `(${u.custId}) ${u.customerName}`,
							},
						]),
				{
					Header: 'Status',
					accessor: ({ deleted }) =>
						deleted ? (
							<span className="badgeC badgeInActive">Inactive</span>
						) : (
							<span className="badgeC badgeActive">Active</span>
						),
				},
				{
					Header: 'Action',
					accessor: (user) => (
						<ActionMenu
							user={user}
							onSelectEdit={handleEditUser}
							onDeleted={() => {
								setDoFetch(true)
							}}
						/>
					),
				},
			],
		},
	]
	return data
}

function ActionMenu({ user, onSelectEdit, onDeleted }) {
	const userDataService = useUserDataService()
	return (
		<>
			<Dropdown>
				<Dropdown.Toggle as={Button} variant="light" id="user-action-dropdown">
					<i className="bi bi-three-dots"></i>
				</Dropdown.Toggle>
				<Dropdown.Menu className="shadow">
					<Dropdown.Item
						eventKey="1"
						as="button"
						onClick={() => {
							onSelectEdit(user)
						}}
					>
						<i className="bi bi-pencil-square"></i> Edit user
					</Dropdown.Item>
					<Dropdown.Item
						eventKey="2"
						as="button"
						onClick={async () => {
							user.deleted = !user.deleted
							await userDataService.update(user.userId, user)
							onDeleted()
						}}
					>
						<i
							className={`bi ${
								user.deleted
									? 'bi-shield text-success'
									: 'bi-shield-slash text-danger'
							}`}
						>
							{user.deleted ? ' Activate user' : ' Deactivate user'}
						</i>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}
