import { useMemo } from 'react'
import { useSessionCookies } from '../auth/useSessionCookies'
import http from '../http-common'

class UserDataService {
	constructor(token, environmentId) {
		this.token = token
		this.environmentId = environmentId
	}

	getAll() {
		return http.get(`/api/environments/${this.environmentId}/users`, {
			headers: {
				'x-access-token': this.token,
				'Content-Type': 'application/json',
			},
		})
	}

	getUsersBycompanyId(companyId) {
		return http
			.get(
				`/api/environments/${this.environmentId}/users?companyId=${companyId}`,
				{
					headers: {
						'x-access-token': this.token,
						'Content-Type': 'application/json',
					},
				},
			)
			.then((resp) => resp.data)
	}

	getAllCompanies() {
		return http
			.get(`/api/environments/${this.environmentId}/company`, {
				headers: {
					'x-access-token': this.token,
					'Content-Type': 'application/json',
				},
			})
			.then((resp) => resp.data)
	}

	getRolesByType(roleType) {
		return http
			.get(`/api/environments/${this.environmentId}/users/roles/${roleType}`, {
				headers: {
					'x-access-token': this.token,
					'Content-Type': 'application/json',
				},
			})
			.then((resp) => resp.data)
	}

	get(id) {
		return http.get(`/api/environments/${this.environmentId}/users/${id}`, {
			headers: {
				'x-access-token': this.token,
				'Content-Type': 'application/json',
			},
		})
	}

	invite(data) {
		return http.post(
			`/api/environments/${this.environmentId}/users/invite`,
			data,
			{
				headers: {
					'x-access-token': this.token,
					'Content-Type': 'application/json',
				},
			},
		)
	}

	update(id, data) {
		return http.put(
			`/api/environments/${this.environmentId}/users/${id}`,
			data,
			{
				headers: {
					'x-access-token': this.token,
					'Content-Type': 'application/json',
				},
			},
		)
	}

	delete(id) {
		return http.delete(`/api/environments/${this.environmentId}/users/${id}`, {
			headers: {
				'x-access-token': this.token,
				'Content-Type': 'application/json',
			},
		})
	}

	findByEmail(email) {
		return http.get(
			`/api/environments/${this.environmentId}/users?email=${email}`,
		)
	}
}

export function useUserDataService() {
	const { user, currentCompany } = useSessionCookies()
	return useMemo(() => {
		if (user?.accessToken && currentCompany) {
			return new UserDataService(
				user.accessToken,
				currentCompany.company.environmentId,
			)
		} else {
			return null
		}
	}, [currentCompany, user?.accessToken])
}
