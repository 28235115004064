import React, { useEffect, useState } from 'react'
import {
	Breadcrumb,
	Tabs,
	Tab,
	Form,
	Alert,
	Button,
	Spinner,
} from 'react-bootstrap'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import useAuth from '../../auth/useAuth'
import SettingsService from '../../services/settings.service'

function getSettingsByGroup(settings) {
	const settingsByGroup = new Map()
	for (const setting of settings) {
		const groupSettings = settingsByGroup.get(setting.group) ?? []
		groupSettings.push(setting)
		settingsByGroup.set(setting.group, groupSettings)
	}
	return settingsByGroup
}

const Settings = () => {
	const { user, currentCompany } = useAuth()
	const [toastMessage, setToastMessage] = useState(null)
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [settingsByGroup, setSettingsByGroup] = useState(new Map())
	const [activeTabKey, setActiveTabKey] = useState()
	const [savingSettings, setSavingSettings] = useState(false)

	useEffect(() => {
		setLoading(true)
		SettingsService.findAll(
			currentCompany.company.environmentId,
			currentCompany.company.id,
			user.accessToken,
		)
			.then((response) => {
				setSettingsByGroup(getSettingsByGroup(response.data))
				setActiveTabKey(response.data[0]?.group)
			})
			.catch((error) => {
				setError(error)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [currentCompany, user])

	const handleSaveSettings = (event) => {
		event.preventDefault()
		setSavingSettings(true)
		SettingsService.update(
			currentCompany.company.environmentId,
			currentCompany.company.id,
			[...settingsByGroup.values()].flat(),
			user.accessToken,
		)
			.then((response) => {
				const updatedSettingsByGroup = getSettingsByGroup(response.data)
				setSettingsByGroup(updatedSettingsByGroup)
				if (!updatedSettingsByGroup.has(activeTabKey)) {
					setActiveTabKey(response.data[0]?.group)
				}
				setToastMessage('Settings saved successfully.')
			})
			.catch((error) => {
				setError(error)
			})
			.finally(() => setSavingSettings(false))
	}

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Settings</Breadcrumb.Item>
				</Breadcrumb>
				<p>
					<b>*** NOTE: THIS FEATURE IS NOT FULLY AVAILABLE ***</b>
				</p>
			</div>
		)
	}

	const handleInputChange = (event, setting) => {
		const { value, checked } = event.target
		const updatedSettings = settingsByGroup.get(setting.group).map((item) => {
			if (item.id === setting.id) {
				return {
					...item,
					value: setting.type === 'switch' ? checked : value,
				}
			}
			return item
		})
		const updatedSettingsByGroup = new Map(settingsByGroup)
		updatedSettingsByGroup.set(setting.group, updatedSettings)
		setSettingsByGroup(updatedSettingsByGroup)
	}

	const SettingsComponent = () => {
		return (
			<Tabs
				activeKey={activeTabKey}
				onSelect={(k) => setActiveTabKey(k)}
				className="mb-3"
			>
				{[...settingsByGroup].map(([groupName, settings]) => (
					<Tab key={groupName} eventKey={groupName} title={groupName}>
						{settings.map((setting) => (
							<Form.Group key={setting.id}>
								{setting.type === 'switch' && (
									<Form.Check
										key={setting.id}
										type={setting.type}
										id={setting.name}
										label={setting.label}
										className="enable-disable-switch"
										defaultChecked={setting.value}
										onChange={(e) => handleInputChange(e, setting)}
									/>
								)}
								{['color', 'number', 'text', null].includes(setting.type) && (
									<>
										<Form.Label>{setting.label}</Form.Label>
										<Form.Control
											type={setting.type}
											key={setting.id}
											id={setting.name}
											placeholder={setting.label}
											value={setting.value}
											onChange={(e) => handleInputChange(e, setting)}
										/>
									</>
								)}
								{['file'].includes(setting.type) && (
									<>
										<Form.Label>{setting.label}</Form.Label>
										<Form.Control
											type={setting.type}
											key={setting.id}
											id={setting.name}
											placeholder={setting.label}
											onChange={(e) => handleInputChange(e, setting)}
										/>
									</>
								)}
							</Form.Group>
						))}
					</Tab>
				))}
			</Tabs>
		)
	}

	return (
		<Form onSubmit={handleSaveSettings}>
			{error ? (
				<Alert dismissible variant="danger" onClose={() => setError(null)}>
					{error.toString()}
				</Alert>
			) : null}
			<HeaderComponent />
			{loading ? (
				<LoadingParagraphs cant={5} animation="glow" />
			) : (
				SettingsComponent()
			)}
			<p />
			<Button variant="primary mt-auto" type="submit" disabled={savingSettings}>
				{savingSettings ? (
					<>
						<Spinner animation="border" role="status" size="sm">
							<span className="visually-hidden">
								Saving Settings, please wait...
							</span>
						</Spinner>{' '}
						Saving...
					</>
				) : (
					'Save Settings'
				)}
			</Button>
		</Form>
	) //TODO: Reload settings to refresh the UI
}
export default Settings
