import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import _ from 'lodash'

const RecordLinesTable = ({ recordLines, linesAccessors }) => {
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns: linesAccessors, data: recordLines },
		useSortBy,
	)

	const TableHead = () => {
		return (
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map((column) => (
							<th {...column.getHeaderProps(column.getSortByToggleProps())}>
								{column.render('Header')}
								<span>
									{!column.isSorted ? '' : column.isSortedDesc ? ' ▼' : ' ▲'}
								</span>
							</th>
						))}
					</tr>
				))}
			</thead>
		)
	}

	const TableBody = () => {
		return (
			<tbody>
				{rows.map((row, i) => {
					prepareRow(row)
					return (
						<tr {...row.getRowProps()}>
							{row.cells.map((cell) => (
								<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
							))}
						</tr>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(recordLines) ? (
		<BTable
			responsive
			hover
			{...getTableProps()}
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.075)' }}
		>
			<TableHead />
			<TableBody />
		</BTable>
	) : (
		<p>There are currently no lines.</p>
	)
}

export default RecordLinesTable
