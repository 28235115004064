export const centeredSquareForm = {
	border: '0.5px grey',
	borderRadius: '1rem',
	boxShadow: 'whitesmoke 0px 10px 50px 10px',
	margin: 0,
	minWidth: '30rem',
	padding: '2.5rem',
	position: 'absolute',
	textAlign: 'center',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '30%',
}

export const containerItem = {
	borderStyle: 'solid',
	borderColor: '#F9FAFC',
}
