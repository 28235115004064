import http from '../../http-common'

export const findAll = (companyId, token) => {
	return http.get(`/api/environments?companyId=${companyId}`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const findOne = (id, token) => {
	return http.get(`/api/environments/${id}`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const create = async (data, token) => {
	return http.post(`/api/environments`, data, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const connect = async (id, token) => {
	return http.patch(
		`/api/environments/${id}/connect`,
		{},
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}

export const update = async (id, data, token) => {
	return http.put(`/api/environments/${id}`, data, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const remove = async (id, token) => {
	return http.delete(`/api/environments/${id}`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const epicorCompaniesList = (environmentId, token) => {
	return http.get(`/api/environments/${environmentId}/company`, {
		headers: {
			'x-access-token': token,
		},
	})
}
