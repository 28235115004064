import { Card, Placeholder, Row } from 'react-bootstrap'

const LoadingCards = ({ cant = 1 }) => {
	const arr = Array.from({ length: cant }, (_, idx) => `${++idx}`)

	return (
		<Row>
			{arr.map((id) => {
				return <LoadingCard key={id} />
			})}
		</Row>
	)
}

const LoadingCard = () => {
	return (
		<Card style={{ width: '18rem', marginRight: '1rem' }}>
			<Card.Body>
				<Placeholder as={Card.Title} animation="glow">
					<Placeholder xs={6} />
				</Placeholder>
				<Placeholder as={Card.Text} animation="glow">
					<Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
					<Placeholder xs={6} /> <Placeholder xs={8} />
				</Placeholder>
				<Placeholder.Button variant="primary" xs={6} />
			</Card.Body>
		</Card>
	)
}

export default LoadingCards
