import useAuth from '../../auth/useAuth'
import ConnectionCard from './connection.card.component'
import { useEffect, useState } from 'react'
import LoadingCards from '../shared/loading/loading.card.component'
import { EnvironmentDataService } from '../../services/epicor'
import { Alert, Row } from 'react-bootstrap'
import _ from 'lodash'

const ConnectionsList = ({ refreshList, onCardClick, onVerify }) => {
	const { user, currentCompany } = useAuth()
	const [environments, setEnvironments] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const hasConnections = !_.isEmpty(environments)

	const handleRemove = (id, token) => {
		EnvironmentDataService.remove(id, token)
			.then(() => {
				fetchEnvironments()
			})
			.catch((error) => setError(error.message))
	}

	const handleSetAsLive = (id, token, isLive) => {
		EnvironmentDataService.update(id, { isLive: isLive }, token)
			.then(() => {
				fetchEnvironments()
			})
			.catch((error) => setError(error.message))
	}

	const fetchEnvironments = () => {
		EnvironmentDataService.findAll(currentCompany.company.id, user.accessToken)
			.then((response) => {
				setEnvironments([response.data])
			})
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		if (!hasConnections) fetchEnvironments()
	}, [])

	useEffect(() => {
		fetchEnvironments()
	}, [refreshList])

	return (
		<>
			{error ? <Alert variant="danger">{error}</Alert> : ''}
			{loading ? (
				<LoadingCards />
			) : hasConnections ? (
				<Row>
					{environments.map((c) => (
						<ConnectionCard
							key={c.id}
							environment={c}
							handleRemove={handleRemove}
							handleSetAsLive={handleSetAsLive}
							onClick={() => onCardClick(c.id)}
							onVerify={onVerify}
						/>
					))}
				</Row>
			) : (
				<p>You don't have any connections yet.</p>
			)}
		</>
	)
}

export default ConnectionsList
