import { Modal } from 'react-bootstrap'
import NewQuery from './new.query.component'
import EditQuery from './edit.query.component'

const QueryForm = ({
	show = false,
	mode = 'create',
	query = [],
	onClose = () => {},
	onOperationCompleted = () => {},
}) => {
	const onCreate = (query) => {
		onOperationCompleted(`Query has been added: ${query.queryID}`)
		onClose()
	}
	const onUpdate = (query) => {
		onOperationCompleted(`Query has been updated: ${query.queryID}`)
		onClose()
	}

	const FormContent = () => {
		const modesContent = {
			create: () => <NewQuery onClose={onClose} onSubmit={onCreate} />,
			edit: () => (
				<EditQuery query={query} onClose={onClose} onSubmit={onUpdate} />
			),
		}

		return modesContent[mode]()
	}

	return (
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					{mode === 'create' ? 'Add Query' : 'Edit Query'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default QueryForm
