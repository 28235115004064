import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import React from 'react'
import ImpersonateModal from './impersonate/impersonate.modal.component'
import { useDispatch, useSelector } from 'react-redux'
import { hideImpersonateModal } from '../state/impersonatedCustomer'
import ImpersonatedUserTopBar from './impersonate/impersonated.user.top.bar.component'
import SideBar from './sidebar/sidebar.component'
import Header from './header/header.component'
import useAuth from '../auth/useAuth'

const isClient = (currentCompany) => !currentCompany.customerId

const MainContent = () => {
	const { currentCompany } = useAuth()
	const dispatch = useDispatch()
	const show = useSelector((state) => state.impersonate.showingModal)

	return (
		<Container fluid className="mt-3">
			{isClient(currentCompany) && (
				<ImpersonateModal
					show={show}
					onSelect={() => dispatch(hideImpersonateModal())}
					onClose={() => dispatch(hideImpersonateModal())}
				/>
			)}
			<Outlet />
		</Container>
	)
}

const Main = () => {
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)

	return (
		<Container fluid md="true">
			<Row>{impersonatedCustomer ? <ImpersonatedUserTopBar /> : null}</Row>
			<Row className="row-fluid">
				<Col className="sidenav" md={'auto'}>
					<SideBar />
				</Col>
				<Col>
					<Header />
					<MainContent />
				</Col>
			</Row>
		</Container>
	)
}
export default Main
