import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import Toaster from '../shared/toaster.component'
import Filters from './filters.component'
import UserForm from './user.form.component'
import UsersList from './users.list.component'

const Users = () => {
	const [showForm, setShowForm] = useState(false)
	const [isClient, setIsClientOrCustomer] = useState(true)
	const [mode, setMode] = useState('create')
	const [userData, setUserData] = useState(null)
	const [toastMessage, setToastMessage] = useState(null)
	const [refreshList, setRefreshList] = useState(0)

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>User Management</Breadcrumb.Item>
				</Breadcrumb>

				<Button
					style={{ float: 'right' }}
					onClick={() => {
						setMode('create')
						setShowForm(true)
					}}
				>
					Add User
				</Button>
			</div>
		)
	}

	const handleEditUser = (u) => {
		setUserData(u)
		if (u !== null) {
			setMode('edit')
			setShowForm(true)
		}
	}

	const handleRefresh = (message) => {
		setRefreshList((prevRefreshList) => prevRefreshList + 1) //TODO: This should update the User List
		if (message) {
			setToastMessage(message)
			setTimeout(() => {
				setToastMessage(null)
			}, 3000)
		}
	}

	return (
		<>
			<HeaderComponent />
			<Filters
				isClient={isClient}
				onClientClick={() => setIsClientOrCustomer(true)}
				onCustomerClick={() => setIsClientOrCustomer(false)}
			/>
			<UsersList
				isClient={isClient}
				handleEditUser={handleEditUser}
				refreshList={refreshList}
			/>
			<UserForm
				show={showForm}
				mode={mode}
				handleClose={() => setShowForm(false)}
				onOperationCompleted={handleRefresh}
				userData={userData}
			/>
			<Toaster msg={toastMessage} header="User" />
		</>
	)
}

export default Users
