import React, { useEffect, useState } from 'react'
import { Alert, Carousel, Card, Button, Spinner, Badge } from 'react-bootstrap'
import useAuth from '../../auth/useAuth'
import { EpicorWidgetService } from '../../services/epicor/'
//import { useSelector } from 'react-redux'
import moment from 'moment'

export default function Home({ refreshList }) {
	const { user, currentCompany } = useAuth()
	const [errorWidgetsQuery, setErrorWidgetsQuery] = useState(null)
	const [widgetsQuery, setWidgetsQuery] = useState(null)
	const [loadingWidgets, setLoadingWidgets] = useState(true)

	const isClient = !currentCompany.attributes
	const customerId = currentCompany.attributes?.id
	const dollarUSLocale = Intl.NumberFormat('en-US')

	const versionDaysOld = (
		(new Date() - new Date(widgetsQuery?.calculatedVersionInstall)) /
		(1000 * 60 * 60 * 24)
	).toFixed()
	const versionDaysMax = parseInt(currentCompany?.settings?.versionDays || 90)

	useEffect(() => {
		setLoadingWidgets(true)
		if (customerId || isClient) {
			EpicorWidgetService.getWidget(
				currentCompany.company.environmentId,
				currentCompany.companyId,
				isClient ? 0 : customerId ?? 0,
				'query',
				user.accessToken,
			)
				.then((response) => {
					if (response.data) {
						setWidgetsQuery(response.data)
						setErrorWidgetsQuery('')
					} else setErrorWidgetsQuery('No Widgets returned!')
				})
				.catch((error) => setErrorWidgetsQuery(error.message))
				.finally(() => setLoadingWidgets(false))
		} else {
			setLoadingWidgets(false)
			setErrorWidgetsQuery('No Widgets returned')
		}
	}, [currentCompany, user, customerId, isClient, refreshList])

	return (
		<>
			{isClient && (
				<div>
					<p>
						Welcome to ElevateERP, a tool that will allow your customers to
						connect with your data, saving you time, increasing accuracy, and
						providing an overall better experience for them.
					</p>
					<div className="flex-wrap">
						<div className="d-flex flex-wrap">
							<Card className="card card-widget">
								<Card.Body className="card-body">
									<h5 className="card-title">Open Orders</h5>
									{loadingWidgets === true && (
										<h2>
											<Spinner animation="border" />
										</h2>
									)}
									{!loadingWidgets && (
										<>
											{errorWidgetsQuery ? (
												<Alert variant="danger">{errorWidgetsQuery}</Alert>
											) : (
												<div>
													<h2>
														<Badge bg="dark">
															{widgetsQuery?.calculatedOrderCount}
														</Badge>
													</h2>
													<h2>
														{' '}
														$
														{dollarUSLocale.format(
															(widgetsQuery?.calculatedOrderAmt ?? 0).toFixed(),
														)}
													</h2>
												</div>
											)}
										</>
									)}
								</Card.Body>
							</Card>
							<Card className="card card-widget">
								<Card.Body className="card-body">
									<h5 className="card-title">Open Invoices</h5>
									{loadingWidgets === true && (
										<h2>
											<Spinner animation="border" />
										</h2>
									)}
									{!loadingWidgets && (
										<>
											{errorWidgetsQuery ? (
												<Alert variant="danger">{errorWidgetsQuery}</Alert>
											) : (
												<div>
													<h2>
														<Badge bg="dark">
															{widgetsQuery?.calculatedInvoiceCount}
														</Badge>
													</h2>
													<h2>
														{' '}
														$
														{dollarUSLocale.format(
															(
																widgetsQuery?.calculatedInvoiceAmt ?? 0
															).toFixed(),
														)}
													</h2>
												</div>
											)}
										</>
									)}
								</Card.Body>
							</Card>
							<Card className="card card-widget">
								<Card.Body className="card-body">
									<h5 className="card-title">ERP Version</h5>
									{loadingWidgets === true && (
										<h2>
											<Spinner animation="border" />
										</h2>
									)}
									{!loadingWidgets && (
										<>
											{errorWidgetsQuery ? (
												<Alert variant="danger">{errorWidgetsQuery}</Alert>
											) : (
												<div>
													<h2>
														<Badge bg="dark">
															{widgetsQuery?.calculatedVersion01}
														</Badge>
													</h2>
													<h2>
														{' '}
														{moment(
															widgetsQuery?.calculatedVersionInstall,
														).format('YYYY-MM-DD')}
													</h2>
													<h2>
														<Badge
															bg={
																versionDaysOld > versionDaysMax
																	? 'danger'
																	: 'success'
															}
														>
															{versionDaysOld} Days
														</Badge>
													</h2>
												</div>
											)}
										</>
									)}
								</Card.Body>
							</Card>
						</div>
					</div>
				</div>
			)}

			{!isClient && (
				<div className="carousel">
					<Carousel interval={4500}>
						<Carousel.Item>
							<img
								className="d-block w-100"
								src="http://jasonwoods.me/uploads/6/9/3/7/69377649/automation_orig.jpeg"
								alt="Time-Saving"
							/>
							<Carousel.Caption>
								<div className="carousel-text">
									<h3>Time-Saving</h3>
									<p>
										With ElevateERP, you can perform many tasks anytime
										anywhere.
									</p>
								</div>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								className="d-block w-100"
								src="http://jasonwoods.me/uploads/6/9/3/7/69377649/reporting_orig.jpeg"
								alt="Your Information Instantly"
							/>
							<Carousel.Caption>
								<div className="carousel-text">
									<h3>Instant</h3>
									<p>
										All related information and documents are easily available.
									</p>
								</div>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img
								className="d-block w-100"
								src="http://jasonwoods.me/uploads/6/9/3/7/69377649/page-layouts-6255498_orig.jpeg"
								alt="Manage"
							/>
							<Carousel.Caption>
								<div className="carousel-text">
									<h3>Manage</h3>
									<p>Create Orders, Pay Invoices, and more!</p>
								</div>
							</Carousel.Caption>
						</Carousel.Item>
					</Carousel>
					<br />
					<div className="flex-wrap">
						<div className="d-flex flex-wrap justify-content-between">
							<Card className="card card-widget">
								<Card.Body className="card-body">
									<h5 className="card-title">Open Orders</h5>
									{errorWidgetsQuery ? (
										<Alert variant="danger">{errorWidgetsQuery}</Alert>
									) : null}
									{loadingWidgets === true && (
										<h2>
											<Spinner animation="border" />
										</h2>
									)}
									{!loadingWidgets && (
										<div>
											<h2>
												<Badge bg="dark">
													{widgetsQuery?.calculatedOrderCount}
												</Badge>
											</h2>
											<h2>
												{' '}
												$
												{dollarUSLocale.format(
													(widgetsQuery?.calculatedOrderAmt ?? 0).toFixed(),
												)}
											</h2>
										</div>
									)}
								</Card.Body>
								<a href="/orders" className="btn btn-primary">
									Go
								</a>
							</Card>
							<Card className="card card-widget">
								<Card.Body className="card-body">
									<h5 className="card-title">Open Invoices</h5>
									{errorWidgetsQuery ? (
										<Alert variant="danger">{errorWidgetsQuery}</Alert>
									) : null}
									{loadingWidgets === true && (
										<h2>
											<Spinner animation="border" />
										</h2>
									)}
									{!loadingWidgets && (
										<div>
											<h2>
												<Badge bg="dark">
													{widgetsQuery?.calculatedInvoiceCount}
												</Badge>
											</h2>
											<h2>
												{' '}
												$
												{dollarUSLocale.format(
													(widgetsQuery?.calculatedInvoiceAmt ?? 0).toFixed(),
												)}
											</h2>
										</div>
									)}
								</Card.Body>
								<a href="/invoices" className="btn btn-primary">
									Go
								</a>
							</Card>
							<Card className="card card-widget">
								<Card.Body className="card-body">
									<h5 className="card-title">Last Shipment</h5>
									{errorWidgetsQuery ? (
										<Alert variant="danger">{errorWidgetsQuery}</Alert>
									) : null}
									{loadingWidgets === true && (
										<h2>
											<Spinner animation="border" />
										</h2>
									)}
									{!loadingWidgets && (
										<div>
											<h2>
												{moment(widgetsQuery?.calculatedLastShipment).format(
													'YYYY-MM-DD',
												)}
											</h2>
											<p>{widgetsQuery?.shipViaDescription}</p>
											<p>
												{widgetsQuery?.trackingNumber === ''
													? 'No Tracking Provided'
													: widgetsQuery?.shipHeadTrackingNumber}
											</p>
										</div>
									)}
								</Card.Body>
								<Button href="/shipments" className="btn btn-primary">
									Go
								</Button>
							</Card>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
