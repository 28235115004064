import { useState } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import useAuth from '../../auth/useAuth'
import { EnvironmentDataService } from '../../services/epicor'
import EditConnection from './edit.connection.component'
import NewConnection from './new.connection.component'
import VerifyConnection from './verify.connection.component'

const ConnectionForm = ({
	show = false,
	mode = 'create',
	handleClose = () => {},
	onOperationCompleted = () => {},
	envId,
}) => {
	const [error, setError] = useState(null)
	const { user } = useAuth()
	const onClose = () => {
		setError(null)
		handleClose()
	}

	const handleCreate = (data) => {
		EnvironmentDataService.create(data, user.accessToken)
			.then((environment) => {
				handleVerifyConnection(environment.data.id)
				onOperationCompleted('A new connection has been added')
				onClose()
			})
			.catch((error) => {
				setError(error.message)
			})
	}

	const handleUpdate = (data) =>
		EnvironmentDataService.update(envId, data, user.accessToken)
			.then(() => {
				onOperationCompleted('All the changes were saved to the connection')
				handleClose()
			})
			.catch((error) => {
				setError(error.message)
			})

	const handleVerifyConnection = (environmentId = envId) => {
		EnvironmentDataService.connect(environmentId, user.accessToken)
			.then(() => {
				onOperationCompleted()
			})
			.catch((error) => {
				setError(error.message)
			})
	}

	const FormContent = () => {
		const modesContent = {
			create: () => (
				<NewConnection handleClose={onClose} handleSubmit={handleCreate} />
			),
			edit: () => (
				<EditConnection
					handleClose={onClose}
					handleSubmit={handleUpdate}
					envId={envId}
				/>
			),
			connect: () => (
				<VerifyConnection
					handleClose={onClose}
					handleVerify={handleVerifyConnection}
					setError={setError}
					envId={envId}
				/>
			),
		}

		return modesContent[mode]()
	}

	return (
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>REST API endpoint</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error ? <Alert variant="danger">{error}</Alert> : ''}
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default ConnectionForm
