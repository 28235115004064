import useAuth from '../../auth/useAuth'
import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { EpicorRMAService } from '../../services/epicor'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import { useSelector } from 'react-redux'
import RMAsTable from './rma.table.component'

const RMAsList = ({ refreshList }) => {
	const { user, currentCompany } = useAuth()
	const [rmas, setRMAs] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)
	const customerId = impersonatedCustomer?.id ?? currentCompany?.attributes?.id

	const printRMA = async (rmaNum) => {
		setError(null)
		EpicorRMAService.print(customerId, rmaNum, user.accessToken)
			.then((response) => {
				const byteCharacters = atob(response.data)
				const byteNumbers = new Array(byteCharacters.length)
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i)
				}
				const byteArray = new Uint8Array(byteNumbers)
				const file = new Blob([byteArray], { type: 'application/pdf;base64' })
				const fileURL = URL.createObjectURL(file)
				var link = document.createElement('a')
				link.download = `${currentCompany.company.company1}_RMA_${rmaNum}.pdf`
				link.href = fileURL
				link.click()
				link.remove()
			})
			.catch((error) => setError(error.message))
	}

	useEffect(() => {
		setLoading(true)
		EpicorRMAService.findAll(customerId, user.accessToken)
			.then((response) => {
				setRMAs(response.data)
			})
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false))
	}, [impersonatedCustomer, currentCompany, refreshList])

	return (
		<div style={containerItem}>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<RMAsTable rmas={rmas} printRMA={printRMA} />
			)}
		</div>
	)
}

export default RMAsList
