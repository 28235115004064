import http from '../../http-common'

export const findAll = (customerId, token) => {
	return http.get(`/api/customers/${customerId}/shipments`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const getLines = (customerId, packNum, token) => {
	return http.get(`/api/customers/${customerId}/shipments/${packNum}/lines`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const print = (customerId, packNum, token) => {
	return http.get(`/api/customers/${customerId}/shipments/${packNum}/print`, {
		headers: {
			'x-access-token': token,
		},
	})
}

export const printBOL = (customerId, packNum, bolNum, token) => {
	return http.get(
		`/api/customers/${customerId}/shipments/${packNum}/printBOL/${bolNum}`,
		{
			headers: {
				'x-access-token': token,
			},
		},
	)
}
