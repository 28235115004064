export const linesAccessors = [
	{
		Header: 'Order Lines',
		columns: [
			{
				Header: 'Line',
				accessor: 'orderLine',
			},
			{
				Header: 'Part',
				accessor: 'partNum',
			},
			{
				Header: 'Description',
				accessor: 'lineDesc',
			},
			{
				Header: 'Qty',
				accessor: 'orderQty',
			},
			{
				Header: 'Price',
				accessor: 'docUnitPrice',
			},
			{
				Header: 'Ext Price',
				accessor: (item) => item.docExtPriceDtl.toFixed(2),
			},
		],
	},
]
