import useAuth from '../../auth/useAuth'
import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import {
	EpicorOrderService,
	EpicorRMAService,
	EpicorInvoiceService,
	EpicorShipmentService,
} from '../../services/epicor'
import { containerItem } from './styles'
import LoadingParagraphs from './loading/loading.paragraph.component'
import { useSelector } from 'react-redux'
import LinesTable from './lines.table.component'

const serviceMap = {
	order: EpicorOrderService,
	rma: EpicorRMAService,
	invoice: EpicorInvoiceService,
	shipment: EpicorShipmentService,
}

const RecordLines = ({ record, type, linesAccessors }) => {
	const { user, currentCompany } = useAuth()
	const [recordLines, setRecordLines] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)

	useEffect(() => {
		setLoading(true)
		const customerId =
			impersonatedCustomer?.id ?? currentCompany?.attributes?.id
		if (customerId != null) {
			serviceMap[type]
				.getLines(customerId, record, user.accessToken)
				.then((response) => {
					setRecordLines(response.data)
				})
				.catch((error) => setError(error.message))
				.finally(() => setLoading(false))
		} else {
			setLoading(false)
		}
	}, [user, currentCompany, impersonatedCustomer, record, type])

	return (
		<div style={containerItem}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={3} />
			) : (
				<LinesTable recordLines={recordLines} linesAccessors={linesAccessors} />
			)}
		</div>
	)
}

export default RecordLines
