import useAuth from '../../auth/useAuth'
import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { EpicorShipmentService } from '../../services/epicor'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import { useSelector } from 'react-redux'
import ShipmentsTable from './shipments.table.component'

const ShipmentsList = ({ refreshList }) => {
	const { user, currentCompany } = useAuth()
	const [shipments, setShipments] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)
	const customerId = impersonatedCustomer?.id ?? currentCompany?.attributes?.id

	const fetchShipments = () => {
		EpicorShipmentService.findAll(customerId, user.accessToken)
			.then((response) => {
				setShipments(response.data)
			})
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false))
	}

	const printShipment = async (packNum) => {
		setError(null)
		EpicorShipmentService.print(customerId, packNum, user.accessToken)
			.then((response) => {
				const byteCharacters = atob(response.data)
				const byteNumbers = new Array(byteCharacters.length)
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i)
				}
				const byteArray = new Uint8Array(byteNumbers)
				const file = new Blob([byteArray], { type: 'application/pdf;base64' })
				const fileURL = URL.createObjectURL(file)
				//TODO: Convert Bytes to PDF and download without "link"
				//window.open(fileURL);
				var link = document.createElement('a')
				link.download = `${currentCompany.company.company1}_Shipment_${packNum}.pdf`
				link.href = fileURL
				link.click()
				link.remove()
			})
			.catch((error) => setError(error.message))
	}

	const printBOL = async (packNum, bolNum) => {
		setError(null)
		EpicorShipmentService.printBOL(
			customerId,
			packNum,
			bolNum,
			user.accessToken,
		)
			.then((response) => {
				const byteCharacters = atob(response.data)
				const byteNumbers = new Array(byteCharacters.length)
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i)
				}
				const byteArray = new Uint8Array(byteNumbers)
				const file = new Blob([byteArray], { type: 'application/pdf;base64' })
				const fileURL = URL.createObjectURL(file)
				//TODO: Convert Bytes to PDF and download without "link"
				//window.open(fileURL);
				var link = document.createElement('a')
				link.download = `${currentCompany.company.company1}_BOL_${bolNum}.pdf`
				link.href = fileURL
				link.click()
				link.remove()
			})
			.catch((error) => setError(error.message))
	}

	useEffect(() => {
		setLoading(true)
		if (customerId) {
			fetchShipments()
		} else {
			setLoading(false)
		}
	}, [customerId, refreshList])

	return (
		<div style={containerItem}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<ShipmentsTable
					shipments={shipments}
					printShipment={printShipment}
					printBOL={printBOL}
				/>
			)}
		</div>
	)
}

export default ShipmentsList
