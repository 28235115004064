import { Button } from 'react-bootstrap'
import { containerItem } from '../shared/styles'

const Filters = ({ isClient, onClientClick, onCustomerClick }) => {
	return (
		<div style={containerItem}>
			<Button
				variant={isClient ? 'dark' : 'outline-dark'}
				onClick={onClientClick}
			>
				Users
			</Button>{' '}
			<Button
				variant={!isClient ? 'dark' : 'outline-dark'}
				onClick={onCustomerClick}
			>
				Customers
			</Button>
		</div>
	)
}

export default Filters
