export const linesAccessors = [
	{
		Header: 'Pack Lines',
		columns: [
			{
				Header: 'Line',
				accessor: 'packLine',
			},
			{
				Header: 'Part',
				accessor: 'partNum',
			},
			{
				Header: 'Description',
				accessor: 'lineDesc',
			},
			{
				Header: 'Qty',
				accessor: (item) => item.ourJobShipQty + item.ourStockShippedQty,
			},
		],
	},
]
