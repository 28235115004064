import React, { useState } from 'react'

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<i
		className="bi bi-three-dots-vertical text-muted"
		role="button"
		ref={ref}
		onClick={(e) => {
			e.preventDefault()
			onClick(e)
		}}
	>
		{children}
	</i>
))

export const CustomMenu = React.forwardRef(
	({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
		const [value, setValue] = useState('')

		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
				<ul className="list-unstyled">
					{React.Children.toArray(children).filter(
						(child) =>
							!value || child.props.children.toLowerCase().startsWith(value),
					)}
				</ul>
			</div>
		)
	},
)
