import { useEffect, useState } from 'react'
import {
	Alert,
	Button,
	Col,
	Form,
	Row,
	Spinner,
	InputGroup,
	Table,
} from 'react-bootstrap'
import useAuth from '../../../auth/useAuth'
import CustomerInfoCard from './company.info.card.component'
import moment from 'moment'
import {
	EpicorCustomerService,
	EpicorOrderService,
} from '../../../services/epicor'
import LoadingCards from '../../shared/loading/loading.card.component'
import AsyncPartTypeahead from '../../shared/part.search.component'
import _ from 'lodash'
import { useSelector } from 'react-redux'

const NewOrder = ({ handleClose, handleSubmit }) => {
	const { user, currentCompany } = useAuth()
	const orderDate = new Date().toISOString().substring(0, 10)
	const [needByDate, setNeedByDate] = useState(null)
	const [error, setError] = useState(null)
	const [poNumber, setPoNumber] = useState('')
	const [comments, setComments] = useState('')
	const [lineItems, setLineItems] = useState([
		{
			ident: new Date().getTime().toString(36),
			part: '',
			description: '',
			qty: 1,
			unitPrice: 0,
		},
	])
	const [currentCustomerInfo, setCurrentCustomerInfo] = useState(null)
	const [currentCustomerAddress, setCurrentCustomerAddress] = useState(null)
	const [shipToNum, setShipToNum] = useState('')
	const [shipToAddresses, setShipToAddresses] = useState([])
	const [shipVias, setShipVias] = useState([])
	const [shipViaCode, setShipViaCode] = useState('')
	const [loadingCCInfo, setLoadingCCInfo] = useState(true)
	const [savingOrder, setSavingOrder] = useState(false)
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)

	const currentCustomer = impersonatedCustomer || currentCompany
	const customerId = impersonatedCustomer?.id ?? currentCustomer?.attributes.id

	const createOrderPayload = () => {
		const buildLineItems = () => {
			return lineItems.map((item) => {
				return {
					part: item.part,
					qty: item.qty,
				}
			})
		}
		return {
			order: {
				poNumber: poNumber,
				company: currentCustomerInfo.company,
				customerId: currentCustomerInfo.custId,
				customerNum: currentCustomerInfo.custNum,
				shipTo: shipToNum,
				date: orderDate,
				needByDate: needByDate,
				comment: comments,
				shipViaCode: shipViaCode,
				lineItems: buildLineItems(),
			},
		}
	}

	const addLineItem = () => {
		const newItems = [
			...lineItems,
			{
				ident: new Date().getTime().toString(36),
				part: '',
				description: '',
				qty: 1,
				unitPrice: 0,
			},
		]
		setLineItems(newItems)
	}

	const handleOnSubmit = (e) => {
		e.preventDefault()
		setSavingOrder(true)

		EpicorOrderService.create(
			createOrderPayload(),
			currentCompany.company.environmentId,
			currentCompany.company.company1,
			customerId,
			user.accessToken,
		)
			.then((response) => {
				const { error } = response.data
				if (error) {
					setError(error[0]?.ErrorText ?? JSON.stringify(error))
					return
				}
				handleSubmit(response.data)
			})
			.catch((error) => {
				setError(error)
			})
			.finally(() => setSavingOrder(false))
	}

	const ShipToOptions = () => {
		return shipToAddresses
			.sort((a, b) => a.name > b.name)
			.map((address, i) => {
				return (
					<option key={i} value={address.shipToNum}>
						{address.name + '-' + address.address1}
					</option>
				)
			})
	}

	const ShipViaOptions = () => {
		return shipVias.map((shipVia, i) => {
			return (
				<option key={i} value={shipVia.shipViaCode}>
					{shipVia.description}
				</option>
			)
		})
	}

	const FormContent = () => {
		return (
			<>
				<h6>
					<strong>Order Details</strong>
				</h6>
				<Form.Group controlId="form.shipTo">
					<Form.Label>Shipping Address</Form.Label>
					<Form.Select
						name="shipTo"
						onChange={(e) => setShipToNum(e.target.value)}
						value={shipToNum || ''}
					>
						<ShipToOptions />
					</Form.Select>
				</Form.Group>

				<Form.Group controlId="form.needBy">
					<Form.Label>Need By</Form.Label>
					<Form.Control
						name="needBy"
						required
						type="date"
						value={needByDate || moment().add(2, 'weeks').format('YYYY-MM-DD')}
						min={moment().format('YYYY-MM-DD')}
						onChange={(e) => setNeedByDate(e.target.value)}
					/>
				</Form.Group>

				<Form.Group controlId="form.po">
					<Form.Label>PO Number</Form.Label>
					<Form.Control
						name="po"
						type="string"
						placeholder="PO #"
						value={poNumber}
						onChange={(e) => setPoNumber(e.target.value)}
					/>
				</Form.Group>
				<Form.Group controlId="form.orderDate">
					<Form.Label>Order Date</Form.Label>
					<Form.Control
						name="orderDate"
						type="date"
						value={orderDate}
						disabled
					/>
				</Form.Group>

				<Form.Group controlId="form.shipVia">
					<Form.Label>Ship Via</Form.Label>
					<Form.Select
						name="shipVia"
						onChange={(e) => setShipViaCode(e.target.value)}
						value={shipViaCode || ''}
					>
						<ShipViaOptions />
					</Form.Select>
				</Form.Group>

				<Form.Group controlId="form.comments">
					<Form.Label>Comments (optional)</Form.Label>
					<Form.Control
						as="textarea"
						rows={3}
						name="comments"
						placeholder="Please, put your comments here."
						value={comments}
						onChange={(e) => setComments(e.target.value)}
					/>
				</Form.Group>
			</>
		)
	}

	const updateLineItem = (lineItem, field, value) => {
		const index = lineItems.findIndex((item) => item.ident === lineItem.ident)
		if (index !== -1) {
			setLineItems((items) => {
				const newLineItems = [...items]
				newLineItems[index] = { ...items[index], [field]: value }
				return newLineItems
			})
		}
	}

	const handlePartSelection = (selectedPart, lineItem) => {
		if (selectedPart) {
			updateLineItem(lineItem, 'part', selectedPart.partNum)
			updateLineItem(lineItem, 'description', selectedPart.partDescription)
			getPriceByQty(lineItem, selectedPart.partNum)
		} else {
			updateLineItem(lineItem, 'description', '')
		}
	}

	const getPriceByQty = (
		lineItem,
		part = lineItem.part,
		qty = lineItem.qty,
	) => {
		EpicorOrderService.itemPriceByQty(
			customerId,
			shipToNum,
			part,
			qty,
			user.accessToken,
		)
			.then((response) => {
				updateLineItem(lineItem, 'unitPrice', response.data.basePrice)
				setError(null)
			})
			.catch((error) => {
				setError('An error ocurred, please check the part on the line item')
			})
	}

	useEffect(() => {
		EpicorCustomerService.customerInfo(customerId, user.accessToken)
			.then((response) => {
				const {
					customerInfo,
					shipToAddresses: addresses,
					address,
				} = response.data
				setCurrentCustomerInfo(customerInfo)
				setCurrentCustomerAddress(address)
				setShipToAddresses(addresses)
				setShipViaCode(customerInfo.ShipVia)
			})
			.catch((error) => {
				setError(error.message)
			})

		EpicorOrderService.shipViaList(
			currentCompany.companyId,
			currentCompany.company.environmentId,
			user.accessToken,
		)
			.then((response) => {
				setShipVias(response.data)
			})
			.catch((error) => {
				setError(error.message)
			})
			.finally(() => setLoadingCCInfo(false))
	}, [impersonatedCustomer, currentCustomer, currentCompany, user])

	return (
		<Form onSubmit={handleOnSubmit}>
			<Row>
				{loadingCCInfo ? (
					<LoadingCards cant={2} />
				) : (
					<>
						{currentCustomerAddress && (
							<Col>
								<CustomerInfoCard
									info={currentCustomerAddress}
									title={'Bill To'}
								/>
							</Col>
						)}
						{!_.isEmpty(shipToAddresses) &&
							shipToAddresses.find((item) => item.shipToNum === shipToNum) && (
								<Col>
									<CustomerInfoCard
										info={shipToAddresses.find(
											(item) => item.shipToNum === shipToNum,
										)}
										title={'Ship To'}
									/>
								</Col>
							)}
					</>
				)}
			</Row>
			<Row>
				<Col className="d-grid gap-4 mt-4">{FormContent()}</Col>
				<Col className="d-flex flex-column">
					<Table>
						<thead>
							<tr>
								<th>Part</th>
								<th>Qty</th>
								<th>Price</th>
								<th>Ext Price</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{lineItems.map((lineItem) => (
								<>
									<tr key={lineItem.ident}>
										<td>
											<Form.Group controlId="form.part.search">
												<InputGroup className="mb-3">
													<AsyncPartTypeahead
														ident={lineItem.ident}
														onChange={(selected) =>
															handlePartSelection(selected, lineItem)
														}
													/>
												</InputGroup>

												{false && ( //TODO: Find better layout for description
													<div
														style={{ fontSize: '0.85rem', color: '#6c757d' }}
													>
														{lineItem.description}
													</div>
												)}
											</Form.Group>
										</td>
										<td>
											<Form.Group>
												<Form.Control
													name="qty"
													type="number"
													min={1}
													value={lineItem.qty}
													onChange={(e) => {
														updateLineItem(lineItem, 'qty', e.target.value)
														getPriceByQty(lineItem)
													}}
												/>
											</Form.Group>
										</td>
										<td>{'$' + lineItem.unitPrice}</td>
										<td>
											{'$' + (lineItem.qty * lineItem.unitPrice).toFixed(2)}
										</td>
										<td>
											<Button
												variant="outline-danger"
												className="bi-x-square"
												onClick={() =>
													setLineItems(
														lineItems.filter(
															(item) => item.ident !== lineItem.ident,
														),
													)
												}
											/>
										</td>
									</tr>
									<tr>
										<td colspan="5">{lineItem.description}</td>
									</tr>
								</>
							))}
						</tbody>
					</Table>
					{!_.isNull(error) ? (
						<Alert dismissible variant="danger" onClose={() => setError(null)}>
							{error}
						</Alert>
					) : null}
					<Button
						className="bi bi-plus-square float-end"
						onClick={addLineItem}
						type="button"
						variant="outline-success"
					/>
					<Button
						variant="primary mt-auto"
						type="submit"
						disabled={savingOrder}
					>
						{savingOrder ? (
							<Spinner animation="border" role="status">
								<span className="visually-hidden">
									Saving Order, please wait...
								</span>
							</Spinner>
						) : (
							'Send Order'
						)}
					</Button>
				</Col>
			</Row>
		</Form>
	)
}

export default NewOrder
