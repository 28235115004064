import { Breadcrumb } from 'react-bootstrap'
import InvoiceList from './invoices.list.component'
import { Elements } from '@stripe/react-stripe-js'
import getStripe from '../../lib/getStripe'

let stripePromise = getStripe()

const Invoices = () => {
	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Invoices</Breadcrumb.Item>
				</Breadcrumb>
			</div>
		)
	}

	return (
		<>
			<Elements stripe={stripePromise}>
				<HeaderComponent />
				<InvoiceList />
			</Elements>
		</>
	)
}

export default Invoices
