export const linesAccessors = [
	{
		Header: 'RMA Lines',
		columns: [
			{
				Header: 'Line',
				accessor: 'rMALine',
			},
			{
				Header: 'Part',
				accessor: 'partNum',
			},
			{
				Header: 'Description',
				accessor: 'lineDesc',
			},
			{
				Header: 'Qty',
				accessor: 'returnQty',
			},
			{
				Header: 'Status',
				accessor: (item) => (item.openDtl ? 'Open' : 'Closed'),
			},
		],
	},
]
