import useAuth from '../../auth/useAuth'
import { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { EpicorInvoiceService } from '../../services/epicor/'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import { useSelector } from 'react-redux'
import InvoicesTable from './invoices.table.component'
import getStripe from '../../lib/getStripe'

const InvoicesList = ({ refreshList }) => {
	const { user, currentCompany } = useAuth()
	const [invoices, setInvoices] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)
	const customerId = impersonatedCustomer?.id ?? currentCompany?.attributes?.id

	const fetchInvoices = () => {
		/** Replace with proper attribute here */
		EpicorInvoiceService.findAll(customerId, user.accessToken)
			.then((response) => {
				setInvoices(response.data)
			})
			.catch((error) => setError(error.message))
			.finally(() => setLoading(false))
	}

	const printInvoice = async (invoiceNum) => {
		setError(null)
		EpicorInvoiceService.print(customerId, invoiceNum, user.accessToken)
			.then((response) => {
				const byteCharacters = atob(response.data)
				const byteNumbers = new Array(byteCharacters.length)
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i)
				}
				const byteArray = new Uint8Array(byteNumbers)
				const file = new Blob([byteArray], { type: 'application/pdf;base64' })
				const fileURL = URL.createObjectURL(file)
				//window.open(fileURL);
				var link = document.createElement('a')
				link.download = `${currentCompany.company.company1}_Invoice_${invoiceNum}.pdf`
				link.href = fileURL
				link.click()
				link.remove()
			})
			.catch((error) => setError(error.message))
	}

	const payInvoice = async (invoice) => {
		const stripe = await getStripe()
		const paymentRequest = stripe.paymentRequest({
			country: 'US',
			currency: 'usd',
			total: {
				label: `Invoice number ${invoice.invoiceNum}`,
				amount: Math.round(invoice.invoiceBal * 100),
			},
			requestPayerName: true,
			requestPayerEmail: true,
		})

		paymentRequest.on('token', ({ complete, token, ...data }) => {
			console.log('Received Stripe token: ', token)
			console.log('Received customer information: ', data)
			complete('success')
		})

		paymentRequest.on('paymentmethod', function (event) {
			// event.paymentMethod is available
			// TODO save to the DB.
		})

		paymentRequest.canMakePayment().then((result) => {
			paymentRequest.show()

			console.log(paymentRequest)
		})
	}

	useEffect(() => {
		setLoading(true)
		if (customerId) {
			fetchInvoices()
		} else {
			setLoading(false)
		}
	}, [customerId, refreshList])

	return (
		<div style={containerItem}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<InvoicesTable
					invoices={invoices}
					printInvoice={printInvoice}
					payInvoice={payInvoice}
				/>
			)}
		</div>
	)
}

export default InvoicesList
