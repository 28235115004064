export const linesAccessors = [
	{
		Header: 'Invoice Lines',
		columns: [
			{
				Header: 'Line',
				accessor: 'invoiceLine',
			},
			{
				Header: 'Part',
				accessor: 'partNum',
			},
			{
				Header: 'Description',
				accessor: 'lineDesc',
			},
			{
				Header: 'Qty',
				accessor: 'ourShipQty',
			},
			{
				Header: 'Unit Price',
				accessor: (item) => item.unitPrice.toFixed(2),
			},
			{
				Header: 'Ext Price',
				accessor: (item) => item.extPrice.toFixed(2),
			},
			{
				Header: 'Discount',
				accessor: (item) => item.discount.toFixed(2),
			},
		],
	},
]
