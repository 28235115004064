import { Col, Container, Row } from 'react-bootstrap'
import PageTitle from './page.title.component'
import UserInfo from './user.info.component'
import { useSelector } from 'react-redux'
import _ from 'lodash'

const Header = () => {
	const { impersonatedCustomer } = useSelector((state) => state.impersonate)

	return (
		<Container fluid>
			<div className="header">
				<Row className="float-start w-100">
					<Col>
						<PageTitle />
					</Col>
					<Col>{_.isNull(impersonatedCustomer) ? <UserInfo /> : null}</Col>
				</Row>
			</div>
		</Container>
	)
}

export default Header
