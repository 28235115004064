import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import useAuth from '../../auth/useAuth'
import PasswordField from '../shared/password.field.component'

const NewConnection = ({ handleClose, handleSubmit }) => {
	const { currentCompany } = useAuth()
	const [showComingSoon, setShowcomingSoon] = useState(false)

	const handleOnSubmit = (e) => {
		e.preventDefault()
		const [url, apiKey, username, password, localName, clientId, baseCompany] =
			[
				e.target.url?.value,
				e.target.apiKey?.value,
				e.target.username?.value,
				e.target.password?.value,
				e.target.localName?.value,
				currentCompany.id,
				e.target.baseCompany?.value,
			]

		handleSubmit({
			url,
			apiKey,
			username,
			password,
			localName,
			clientId,
			baseCompany,
		})
	}
	return (
		<Form onSubmit={handleOnSubmit}>
			<div className="d-grid gap-4">
				<Form.Group controlId="form.url">
					<Form.Label>REST API Endpoint URL</Form.Label>
					<Form.Control
						name="url"
						type="text"
						placeholder="https://epicor.mycompany.com/EpicorTest10/api/v2"
						autoFocus
					/>
				</Form.Group>
				<Form.Group controlId="form.apiKey">
					<Form.Label>API Keys</Form.Label>
					<PasswordField name="apiKey" placeholder="Enter the API Key" />
				</Form.Group>
				<Form.Group controlId="form.username">
					<Form.Label>Username</Form.Label>
					<Form.Control
						name="username"
						type="text"
						placeholder="Epicor Username"
					/>
				</Form.Group>
				<Form.Group controlId="form.password">
					<Form.Label>Password</Form.Label>
					<PasswordField name="password" placeholder="Epicor Password" />
				</Form.Group>
				<Form.Group controlId="baseCompany">
					<Form.Label>Base Company ID</Form.Label>
					<Form.Control type="text" placeholder="EPIC03" />
				</Form.Group>
				<Form.Group controlId="localName">
					<Form.Label>Environment Title</Form.Label>
					<Form.Control type="text" placeholder="Environment title" />
				</Form.Group>

				<Button variant="primary mt-3" type="submit">
					Save New Connection
				</Button>
				<Button
					variant="light"
					onClick={() => setShowcomingSoon(!showComingSoon)}
				>
					<p className="mb-0">Help Filling REST API Endpoint</p>
					{showComingSoon && <small className="text-center">Coming soon</small>}
				</Button>
			</div>
		</Form>
	)
}

export default NewConnection
