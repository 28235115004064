//TODO: Move this to Environment and Customer service
import http from '../../http-common'

export const getWidget = (
	environmentId,
	companyId,
	customerId,
	widgetName,
	token,
) => {
	if (customerId === 0) {
		return http.get(
			`/api/environments/${environmentId}/company/${companyId}/widgets/${widgetName}`,
			{
				headers: {
					'x-access-token': token,
				},
			},
		)
	} else {
		return http.get(`/api/customers/${customerId}/widgets/${widgetName}`, {
			headers: {
				'x-access-token': token,
			},
		})
	}
}
