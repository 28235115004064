import http from '../http-common'
class SettingsService {
	findAll = (environmentId, company, token) => {
		return http.get(
			`/api/environments/${environmentId}/company/${company}/settings`,
			{
				headers: {
					'x-access-token': token,
				},
			},
		)
	}

	findOne = (environmentId, company, settingsId, token) => {
		return http.get(
			`/api/environments/${environmentId}/company/${company}/settings/${settingsId}`,
			{
				headers: {
					'x-access-token': token,
				},
			},
		)
	}

	update = (environmentId, company, data, token) => {
		return http.put(
			`/api/environments/${environmentId}/company/${company}/settings`,
			data,
			{
				headers: {
					'x-access-token': token,
					'Content-Type': 'application/json',
				},
			},
		)
	}
}
const settingsService = new SettingsService()
export default settingsService
