import moment from 'moment'
import { Dropdown, Button } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = (printPack, printBOL) => [
	{
		Header: 'Shipment List',
		columns: [
			{
				Header: 'Pack #',
				accessor: 'shipHeadPackNum',
			},
			{
				Header: 'Ship Date',
				accessor: (item) =>
					item.shipHeadShipDate != null
						? moment(item.shipHeadShipDate).format('YYYY-MM-DD')
						: '',
			},
			{
				Header: 'Status',
				accessor: 'shipHeadShipStatus',
			},
			{
				Header: 'Shipped From',
				accessor: 'plantState',
			},

			{
				Header: 'Ship To',
				accessor: (item) => {
					return (
						<div>
							{item.calculatedAddress?.split('~').map((addrLine) => (
								<div>
									{addrLine}
									<br />
								</div>
							))}
						</div> //TODO: Check if this is in the BAQ
					)
				},
			},
			{
				Header: 'Ship Via',
				accessor: 'shipViaDescription',
			},
			{
				Header: 'BOL',
				accessor: (item) =>
					item.shipHeadBOLNum === 0 ? '' : item.shipHeadBOLNum,
			},
			{
				Header: 'Tracking Number',
				accessor: (item) => {
					return (
						<Button
							href={item.calculatedTrackingURL}
							variant="link"
							target="_blank"
						>
							{item.shipHeadTrackingNumber}
						</Button>
					)
				},
			},
			{
				Header: '⚡',
				accessor: (item) => {
					return (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => printPack(item.shipHeadPackNum)}
								>
									Download Pack
								</Dropdown.Item>
								<Dropdown.Item
									eventKey="2"
									onClick={() =>
										printBOL(item.shipHeadPackNum, item.shipHeadBOLNum)
									}
								>
									Download Bill of Lading
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)
				},
			},
		],
	},
]
