import moment from 'moment'
import { Dropdown } from 'react-bootstrap'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

export const accessors = (printInvoice, payInvoice) => [
	{
		Header: 'Invoice List',
		columns: [
			{
				Header: 'Invoice#',
				accessor: 'invoiceNum',
			},
			{
				Header: 'CM',
				accessor: (item) => (item.creditMemo ? '✓' : ''),
			},
			{
				Header: 'PO#',
				accessor: 'pONum',
			},
			{
				Header: 'Due Date',
				accessor: (item) =>
					item.dueDate != null ? moment(item.dueDate).format('YYYY-MM-DD') : '',
			},
			{
				Header: 'Invoice Amount',
				accessor: (item) => parseFloat(item.invoiceAmt).toFixed(2),
			},
			{
				Header: 'Invoice Balance',
				accessor: (item) => parseFloat(item.invoiceBal).toFixed(2),
			},
			{
				Header: 'Open Invoice',
				accessor: (item) => (item.openInvoice ? '✓' : ''),
			},
			{
				Header: '⚡',
				accessor: (item) => {
					return (
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id="dropdown-custom-components"
							/>
							<Dropdown.Menu as={CustomMenu}>
								<Dropdown.Item
									eventKey="1"
									onClick={() => printInvoice(item.invoiceNum)}
								>
									Download Invoice
								</Dropdown.Item>
								{item.openInvoice && item.invoiceBal > 0 && (
									<Dropdown.Item eventKey="2" onClick={() => payInvoice(item)}>
										Pay Invoice
									</Dropdown.Item>
								)}
							</Dropdown.Menu>
						</Dropdown>
					)
				},
			},
		],
	},
]
