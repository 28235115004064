import { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, InputGroup } from 'react-bootstrap'

const PasswordField = ({ name, placeholder, onChange, value }) => {
	const [type, setType] = useState('password')

	const handleShowPasswordClick = () => {
		setType(type === 'text' ? 'password' : 'text')
	}

	return (
		<InputGroup>
			<Form.Control
				name={name}
				required
				size="lg"
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
			/>
			<InputGroup.Text type={type} onClick={handleShowPasswordClick}>
				<span style={{ minWidth: '3rem' }} role="button">
					{type === 'text' ? 'Hide' : 'Show'}
				</span>
			</InputGroup.Text>
			<Form.Control.Feedback type="invalid">{`Please enter ${name}`}</Form.Control.Feedback>
		</InputGroup>
	)
}

PasswordField.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
}

export default PasswordField
