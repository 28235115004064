import { Toast } from 'react-bootstrap'

const Toaster = ({ msg, header = '', style = 'primary' }) => {
	return (
		<Toast
			style={{ position: 'absolute', right: 10, bottom: 10 }}
			show={!!msg}
			delay={5000}
			className="d-inline-block m-1"
			bg={style}
		>
			<Toast.Header>
				<img
					src="holder.js/20x20?text=%20"
					aria-hidden="true"
					className="rounded me-2"
					alt=""
				/>
				<strong className="me-auto">{header}</strong>
			</Toast.Header>
			<Toast.Body>{msg}</Toast.Body>
		</Toast>
	)
}

export default Toaster
