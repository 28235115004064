import { Placeholder, Row } from 'react-bootstrap'

const LoadingParagraphs = ({ cant = 1 }) => {
	const arr = Array.from({ length: cant }, (_, idx) => `${++idx}`)

	return (
		<Row>
			{arr.map((id) => {
				return <LoadingParagraph key={id} />
			})}
		</Row>
	)
}

const LoadingParagraph = () => {
	return (
		<p aria-hidden="true">
			<Placeholder xs={6} className="w-100" />
		</p>
	)
}

export default LoadingParagraphs
