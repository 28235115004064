import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Image } from 'react-bootstrap'
import useAuth from '../../auth/useAuth'
import CompanyChooser from './company-chooser.component'
import { centeredSquareForm } from '../shared/styles'
import PasswordField from '../shared/password.field.component'
import logo from '../../logo.png'

const Login = () => {
	const navigate = useNavigate()
	const { signin, user, loading, settleOnCompany } = useAuth()
	const [disableBtn, setDisableLoginButton] = useState(true)
	const [me, setMe] = useState(null)
	const [errorMsg, setErrorMsg] = useState(null)

	// authenticate against Signin API.  if the returned user has more then 1 customer + client solicit
	// which one he wants to home into
	const handleLogin = (e) => {
		setDisableLoginButton(true)
		e.preventDefault()
		const [email, password] = [e.target.email?.value, e.target.password?.value]
		signin(email, password)
			.then((user) => {
				if (user.customerAndClientCount === 1) {
					settleOnCompany(
						user.customers.length > 0 ? user.customers[0] : user.clients[0],
					)
					navigate('/home')
				} else {
					setMe(user)
				}
			})
			.catch((error) => {
				setErrorMsg(error.message)
			})
		setDisableLoginButton(false)
	}

	const resetPassword = (e) => {
		e.preventDefault()
		navigate('/reset-password')
	}

	function checkFieldsFilled(e) {
		setDisableLoginButton(!e.currentTarget.form.checkValidity())
	}

	useEffect(() => {
		setMe(user)
	}, [user])

	if (loading) return <div>Loading...</div>

	return !me ? (
		<div className="container">
			<Form
				style={centeredSquareForm}
				autoComplete="on"
				onSubmit={handleLogin}
				className="needs-validation"
				noValidate
			>
				<div className="d-grid gap-4">
					<Image src={logo} alt="ElevateERP" />
					<Form.Group controlId="email">
						<Form.Control
							required
							size="lg"
							type="email"
							placeholder="Email"
							onChange={checkFieldsFilled}
						/>
						<Form.Control.Feedback type="invalid">
							Please enter email address
						</Form.Control.Feedback>
					</Form.Group>
					<PasswordField
						name="password"
						placeholder="Password"
						onChange={checkFieldsFilled}
					/>
					<Button
						size="lg"
						variant={disableBtn ? 'secondary' : 'primary'}
						type="submit"
						disabled={disableBtn}
					>
						Login
					</Button>
					<span style={{ color: 'red' }}>{errorMsg}</span>
				</div>
				<a
					className="link-dark text-decoration-none"
					href="/"
					onClick={resetPassword}
				>
					Forgot password?
				</a>
			</Form>
		</div>
	) : (
		<div style={centeredSquareForm} className="d-grid gap-4">
			<Image src={logo} alt="ElevateERP" />
			<CompanyChooser {...{ me, setMe, setErrorMsg }} />
		</div>
	)
}

export default Login
