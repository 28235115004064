import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import Toaster from '../shared/toaster.component'
import ConnectionForm from './connection.form.component'
import ConnectionsList from './connections.list.component'

const EpicorConnections = () => {
	const [showForm, setShowForm] = useState(false)
	const [mode, setMode] = useState('create')
	const [envId, setEnvId] = useState(null)
	const [toastMessage, setToastMessage] = useState(null)
	const [refreshList, setRefreshList] = useState(0)

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Epicor Connections</Breadcrumb.Item>
				</Breadcrumb>

				<Button
					style={{ float: 'right' }}
					onClick={() => {
						setMode('create')
						setShowForm(true)
					}}
				>
					Add Connection
				</Button>
			</div>
		)
	}

	const handleRefresh = (message) => {
		setRefreshList((prevRefreshList) => prevRefreshList + 1)
		if (message) {
			setToastMessage(message)
			setTimeout(() => {
				setToastMessage(null)
			}, 3000)
		}
	}

	const handleEditConnection = (id) => {
		setMode('edit')
		setEnvId(id)
		setShowForm(true)
	}

	const handleVerifyConnection = (id) => {
		setMode('connect')
		setEnvId(id)
		setShowForm(true)
	}

	return (
		<>
			<HeaderComponent />
			<ConnectionsList
				refreshList={refreshList}
				onCardClick={handleEditConnection}
				onVerify={handleVerifyConnection}
			/>
			<ConnectionForm
				show={showForm}
				mode={mode}
				handleClose={() => {
					setShowForm(false)
					handleRefresh()
				}}
				onOperationCompleted={handleRefresh}
				envId={envId}
			/>
			<Toaster msg={toastMessage} />
		</>
	)
}

export default EpicorConnections
