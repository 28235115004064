import { useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import Toaster from '../shared/toaster.component'
import QueriesList from './queries.list.component'
import QueryForm from './query.form.component'
import RunQueryForm from './run.query.form.component'
import useAuth from '../../auth/useAuth'

const Queries = () => {
	const [showForm, setShowForm] = useState(false)
	const [showRunForm, setShowRunForm] = useState(false)
	const [mode, setMode] = useState('create')
	const [query, setQuery] = useState(null)
	const [toastMessage, setToastMessage] = useState(null)
	const [refreshList, setRefreshList] = useState(0)
	const { currentCompany } = useAuth()

	const HeaderComponent = () => {
		return (
			<div className="header">
				<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
					<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					<Breadcrumb.Item active>Queries</Breadcrumb.Item>
				</Breadcrumb>
				{currentCompany?.role?.name.toLowerCase() === 'admin' ? (
					<Button
						style={{ float: 'right' }}
						onClick={() => {
							setMode('create')
							setShowForm(true)
						}}
					>
						Add Query
					</Button>
				) : (
					''
				)}
			</div>
		)
	}

	const onEditQuery = (query) => {
		setMode('edit')
		setQuery(query)
		setShowForm(true)
	}

	const onRunQuery = (query) => {
		setShowRunForm(true)
		setQuery(query)
	}

	const onRefresh = (message) => {
		setRefreshList((prevRefreshList) => prevRefreshList + 1)
		if (message) {
			setToastMessage(message)
			setTimeout(() => {
				setToastMessage(null)
			}, 3000)
		}
	}

	return (
		<>
			<HeaderComponent />
			<QueriesList
				onEditQuery={onEditQuery}
				onRunQuery={onRunQuery}
				refreshList={refreshList}
				onRefresh={onRefresh}
				companyRole={currentCompany?.role?.name}
			/>
			<QueryForm
				show={showForm}
				mode={mode}
				onClose={() => setShowForm(false)}
				onOperationCompleted={onRefresh}
				query={query}
			/>
			{query && (
				<RunQueryForm
					show={showRunForm}
					onClose={() => setShowRunForm(false)}
					query={query}
				/>
			)}
			<Toaster msg={toastMessage} />
		</>
	)
}

export default Queries
